import { firmeClient } from "@/api/utils";
import { SigneeTypeRepresentation } from "@/enums/SigneeTypeRepresentation.enum";

type SigneeRepresentation = {
  type: SigneeTypeRepresentation;
  name: string;
  documentNumber: string;
};

type TUpdateSigneeData = {
  documentCode: string;
  privateCode: string;
  firstName: string | null;
  lastName: string | null;
  maternalLastName?: string | null;
  documentNumber: string | null;
  email: string | null;
  inRepresentationOf?: {
    name: string | null;
    documentNumber: string | null;
  } | null;
};

type TCreateSigneeData = {
  documentCode: string;
  firstName: string;
  lastName: string;
  maternalLastName: string;
  documentNumber: string;
  email: string;
  phoneNumber: string | null;
  inRepresentationOf: SigneeRepresentation | null;
};

export const createSignee = async ({
  documentCode,
  ...data
}: TCreateSigneeData) => {
  return firmeClient
    .post(`/documents/${documentCode}/signees`, data)
    .then((res) => res.data);
};

export const updateSignee = async ({
  documentCode,
  privateCode,
  ...data
}: TUpdateSigneeData) => {
  return firmeClient
    .put(`/documents/${documentCode}/signees/${privateCode}`, data)
    .then((res) => res.data);
};

export const removeSignee = async ({
  documentCode,
  privateCode,
}: {
  documentCode: string;
  privateCode: string;
}) => {
  return firmeClient
    .delete(`/documents/${documentCode}/signees/${privateCode}`)
    .then((res) => res.data);
};
