import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useQuery } from "@tanstack/react-query";
import { Check, ChevronDown } from "lucide-react";
import { useMemo, useState } from "react";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { TCategoryItem, listCategories } from "@/api/v2/documents";

const useCategories = () => {
  const query = useQuery({
    queryKey: ["listCategories"],
    queryFn: listCategories,
  });

  return useMemo(() => {
    const categories: Record<
      string,
      {
        value: string;
        label: string;
        options: TCategoryItem[];
      }
    > = {};
    const others: TCategoryItem[] = [];

    for (const item of query.data ?? []) {
      if (item.parent === "OTROS") {
        others.push(item);
        continue;
      }

      if (!categories[item.parent]) {
        categories[item.parent] = {
          value: item.parent,
          label: item.parent.replace("_", " "),
          options: [],
        };
      }

      categories[item.parent].options.push(item);
    }

    return [
      ...Object.entries(categories).flatMap((x) => x[1]),
      {
        value: "OTROS",
        label: "OTROS",
        options: others,
      },
    ];
  }, [query.data]);
};

const CategoriesSelector = ({
  defaultValue,
  onChange,
}: {
  defaultValue: {
    category: string;
    subcategory: string;
  };
  onChange: ({
    category,
    subcategory,
  }: {
    category: string;
    subcategory: string;
  }) => void;
}) => {
  const [open, setOpen] = useState(false);
  const options = useCategories();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className="w-full justify-between"
        >
          <span>
            {defaultValue.subcategory
              ? defaultValue.subcategory
              : "Seleccionar categoría"}
          </span>
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="min-w-[600px] p-0" align="end">
        <Command
          filter={(value, search) => {
            if (
              value
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  search
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, ""),
                )
            )
              return 1;
            return 0;
          }}
        >
          <CommandInput placeholder="Filtrar categorías" />
          <CommandEmpty>Categorías no encontradas</CommandEmpty>
          <CommandList>
            {options.map((group) => (
              <CommandGroup key={group.value} heading={group.label}>
                {group.options.map((item) => (
                  <CommandItem
                    key={item.name}
                    value={item.name}
                    className="cursor-pointer"
                    onSelect={(currentValue) => {
                      onChange({
                        category: group.value,
                        subcategory: currentValue,
                      });
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        defaultValue.subcategory === item.name
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />

                    {item.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export { CategoriesSelector };
