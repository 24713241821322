import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatSeconds(total: number) {
  const minutes = Math.floor(total / 60);
  const seconds = total % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}

export function formatCurrency(value: number) {
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(value);
}

export function creditsQuantityRender(quantity: number) {
  return `${quantity}${quantity === 1 ? " crédito" : " créditos"}`;
}

export function shortCode(code: string) {
  return code.split("-").pop()?.slice(0, 5).toUpperCase() ?? "";
}

export function saveFileToDisk(file: File, filename: string) {
  const a = document.createElement("a");
  const url = window.URL.createObjectURL(file);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}
