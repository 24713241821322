import {
  createDocument,
  listDocuments,
  notifyDocumentUpload,
} from "@/api/v2/documents";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Progress } from "@/components/ui/progress";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { useAppStore } from "@/stores/AppStore";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosHeaders } from "axios";
import { FileUpIcon } from "lucide-react";
import { useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";

const FilePicker = ({
  transactionId,
  onFileUploaded,
}: {
  transactionId: string;
  onFileUploaded: () => void;
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationKey: ["createDocument"],
    mutationFn: createDocument,
    onSuccess: async (res, data) => {
      const headers = new AxiosHeaders();
      headers.set("Content-Type", "application/pdf");

      return axios
        .put(res.uploadUrl, data.file, {
          headers,
          onUploadProgress: (e) => setUploadProgress((e.progress ?? 0) * 100),
        })
        .then(() => notifyDocumentUpload(res.code))
        .then(() =>
          navigate(`/transactions/${transactionId}/documents/${res.code}/new`),
        );
    },
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files: File[]) =>
      mutation.mutate(
        {
          transactionId,
          file: files[0],
        },
        {
          onSuccess: () => onFileUploaded(),
        },
      ),
    onDropRejected: (fileRejections: FileRejection[]) => {
      let message = "";

      for (const err of fileRejections) {
        if (err.errors[0].message.startsWith("File is larger than")) {
          message =
            "El archivo es demasiado grande. Solo se admiten archivos de hasta 10MB";
          continue;
        } else {
          message = "El archivo no es un PDF";
          continue;
        }
      }

      setErrorMessage(message);
      setShowAlert(true);
    },

    multiple: false,
    maxSize: 10 * 1024 * 1024,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  if (mutation.isPending || mutation.isSuccess) {
    return (
      <div
        className={cn(
          "flex flex-col items-center gap-10",
          "rounded-lg border border-2 border-primary bg-primary/40 py-12",
        )}
      >
        <FileUpIcon size={64} />
        <div className="w-[50%]">
          <Progress value={uploadProgress} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={cn(
          "cursor-pointer rounded-lg border border-2 border-dashed p-10",
          "border-primary bg-primary/40",
          "hover:bg-primary/50",
          "transition-all",
        )}
        {...getRootProps()}
      >
        <input {...getInputProps()} type="file" className="hidden" />

        <div className="flex flex-col items-center justify-center gap-4">
          <FontAwesomeIcon icon={faCloudUpload} size="4x" />

          <div className="text-center md:hidden">
            Haz click para seleccionar el archivo (Solo archivos en formato PDF)
          </div>

          <div className="hidden text-center md:block">
            {isDragActive ? (
              "Suelta aquí el archivo"
            ) : (
              <span>
                Arrastra aquí el archivo o haz click para seleccionarlo.
                <br /> Solo archivos en formato PDF.
                <br /> Tamaño máximo de archivo 10MB
              </span>
            )}
          </div>
        </div>
      </div>

      <AlertDialog open={showAlert} onOpenChange={setShowAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Error de archivo</AlertDialogTitle>
            <AlertDialogDescription>{errorMessage}</AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogCancel>Cerrar</AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

const TransactionFilesPage = () => {
  const { transactionId } = useParams<{ transactionId: string }>();
  const { legalEntity } = useAppStore();

  const query = useQuery({
    queryKey: [
      "getTransactionDocuments",
      { legalEntityId: legalEntity?.id, transactionId },
    ],
    queryFn: () =>
      listDocuments({
        legalEntityId: legalEntity?.id as string,
        transactionId,
      }),
    enabled: !!legalEntity,
  });

  if (query.isLoading) {
    return <Skeleton className="h-96 w-full" />;
  }

  if (transactionId) {
    return (
      <FilePicker
        transactionId={transactionId}
        onFileUploaded={() => query.refetch()}
      />
    );
  }
};

export { TransactionFilesPage };
