import { listLegalEntities } from "@/api/v2/legal-entities";
import { cn } from "@/lib/utils";
import { useAppStore } from "@/stores/AppStore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CommandList, CommandLoading } from "cmdk";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../ui/command";

const LegalEntitySelector = () => {
  const { legalEntity, setLegalEntity } = useAppStore();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["getLegalEntities"],
    queryFn: listLegalEntities,
  });

  const legalEntities = query.data?.items ?? [];

  return (
    <Command>
      <CommandInput placeholder="Buscar empresa..." />

      {!query.isLoading && legalEntities.length === 0 && (
        <CommandEmpty>Sin empresas encontradas</CommandEmpty>
      )}

      <CommandList>
        {query.isLoading && (
          <CommandLoading className="py-2 text-center">
            Cargando...
          </CommandLoading>
        )}

        <CommandGroup>
          {legalEntities.map((item) => (
            <CommandItem
              className="flex cursor-pointer flex-row items-center justify-between"
              key={item.id.toString()}
              value={item.name}
              onSelect={() => {
                setLegalEntity(item);
                queryClient.invalidateQueries({
                  queryKey: ["getDocuments"],
                });
              }}
            >
              <div className="flex flex-row items-center">
                <span
                  className={cn([
                    "mr-2 inline-block h-2 w-2 rounded-full",
                    legalEntity?.documentNumber === item.documentNumber
                      ? "bg-foreground"
                      : "bg-transparent",
                  ])}
                ></span>
                <span className="mr-3 max-w-[180px] truncate">{item.name}</span>
              </div>
              <div>{item.documentNumber}</div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};

export { LegalEntitySelector };
