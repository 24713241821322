import { ProcedureTypeMapper, TListDocumentItem } from "@/api/v2/documents";
import { createTransaction } from "@/api/v2/transactions";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useAppStore } from "@/stores/AppStore";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { DataTablePagination } from "../ui/data-table-pagination";
import { DocumentStatusBadge } from "./DocumentStatusBadge";
import {
  DocumentAuthorFilter,
  DocumentLabelsFilter,
  DocumentStatusFilter,
  DocumentSubcategoryFilter,
  ProcedureTypeFilter,
} from "./DocumentsFilters";
import { shortCode } from "@/lib/utils";

const columns: ColumnDef<TListDocumentItem>[] = [
  {
    id: "code",
    accessorKey: "code",
    header: "ID",
    cell: ({ row }) => {
      return (
        <Link
          to={`/documents/${row.original.code}`}
          className="font-semibold text-foreground hover:underline"
        >
          <code>{shortCode(row.original.code)}</code>
        </Link>
      );
    },
  },
  {
    id: "name",
    accessorKey: "name",
    header: "Nombre",
    cell: ({ row }) => {
      return <div className="w-60 truncate">{row.original.name}</div>;
    },
  },
  {
    id: "subcategory",
    accessorKey: "subcategory",
    header: "Categoría",
    filterFn: "equalsString",
  },
  {
    id: "procedureType",
    accessorKey: "procedureType",
    header: "Procedimiento",
    cell: ({ row }) => {
      return (
        <div className="flex gap-1">
          {ProcedureTypeMapper[row.original.procedureType]}
        </div>
      );
    },
  },
  {
    id: "labels",
    accessorKey: "labels",
    header: "Etiquetas",
    cell: ({ row }) => {
      return (
        <div className="flex gap-1">
          {row.original.labels.map((label) => (
            <Badge key={label} variant="outline" className="whitespace-nowrap">
              {label}
            </Badge>
          ))}
        </div>
      );
    },
    filterFn: (row, _, filterValue) => {
      const parsedValue = (filterValue as string | undefined) ?? "";
      const values = parsedValue === "" ? [] : parsedValue.split(",");
      if (values.length == 0) {
        return true;
      }

      const filtered = row.original.labels.filter((x) => values.includes(x));
      return filtered.length > 0;
    },
  },
  {
    id: "author",
    accessorFn: (originalRow) =>
      `${originalRow.user.firstName} ${originalRow.user.lastName}`,
    header: "Autor",

    cell: ({ row }) => {
      return (
        <span className="whitespace-nowrap">
          {row.original.user.firstName} {row.original.user.lastName}
        </span>
      );
    },
  },
  {
    id: "signees",
    accessorKey: "signees",
    header: "Firmantes",
    cell: ({ row }) => (
      <div>
        {row.original.signeesCount.signed} / {row.original.signeesCount.total}
      </div>
    ),
  },
  {
    id: "status",
    accessorKey: "status",
    header: "Estado",
    cell: ({ row }) => {
      return (
        <div className="">
          <DocumentStatusBadge status={row.original.status} />
        </div>
      );
    },
  },
];

type DocumentsListProps = {
  data: TListDocumentItem[];
  isLoading: boolean;
};

const CreateTransactionButton = () => {
  const navigate = useNavigate();
  const { legalEntity } = useAppStore();

  const mutation = useMutation({
    mutationKey: ["createTransaction"],
    mutationFn: createTransaction,
    onSuccess: (data) => {
      navigate(`/transactions/${data.id}/files`);
    },
  });

  return (
    <Button
      disabled={mutation.isPending || !legalEntity}
      onClick={() =>
        mutation.mutate({
          legalEntityId: legalEntity?.id as string,
        })
      }
    >
      <FontAwesomeIcon
        icon={mutation.isPending ? faSpinner : faPlus}
        className="mr-2"
        spin={mutation.isPending}
      />
      <span>Nuevo documento</span>
    </Button>
  );
};

const DocumentsList = ({ data, isLoading }: DocumentsListProps) => {
  const { user, legalEntity } = useAppStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const showAuthor = useMemo(
    () =>
      legalEntity?.personType === "JURIDICAL" &&
      legalEntity.role == "LEGAL_ENTITY_ADMINISTRATOR",
    [legalEntity],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: parseInt(searchParams.get("page") ?? "0"),
        pageSize: parseInt(searchParams.get("limit") ?? "20"),
      },
    },
  });

  useEffect(() => {
    if (user && legalEntity) {
      const col = table.getAllColumns().find((x) => x.id === "author");

      if (showAuthor) {
        col?.toggleVisibility(true);
      } else {
        col?.toggleVisibility(false);
      }
    }
  }, [user, legalEntity, table, showAuthor]);

  useEffect(() => {
    table.getColumn("status")?.setFilterValue(searchParams.get("status"));

    table
      .getColumn("subcategory")
      ?.setFilterValue(searchParams.get("subcategory"));

    table
      .getColumn("procedureType")
      ?.setFilterValue(searchParams.get("procedure"));

    table.getColumn("author")?.setFilterValue(searchParams.get("author"));
    table.getColumn("labels")?.setFilterValue(searchParams.get("labels"));
  }, [searchParams, table]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex grow items-center gap-4">
          <Input
            placeholder="Filtrar por nombre de documento"
            value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
            onChange={(event) =>
              table.getColumn("name")?.setFilterValue(event.target.value)
            }
            className="max-w-xs"
          />

          <DocumentStatusFilter
            selectedValue={
              searchParams.has("status") ? searchParams.get("status") : null
            }
            onChange={(value) => {
              setSearchParams((prev) => {
                if (value) {
                  prev.set("status", value?.value);
                } else {
                  prev.delete("status");
                }
                return prev;
              });
            }}
          />

          <DocumentSubcategoryFilter
            selectedValue={
              searchParams.has("subcategory")
                ? searchParams.get("subcategory")
                : null
            }
            onChange={(value) => {
              setSearchParams((prev) => {
                if (value) {
                  prev.set("subcategory", value);
                } else {
                  prev.delete("subcategory");
                }
                return prev;
              });
            }}
          />

          <ProcedureTypeFilter
            selectedValue={
              searchParams.has("procedure")
                ? searchParams.get("procedure")
                : null
            }
            onChange={(value) => {
              setSearchParams((prev) => {
                if (value) {
                  prev.set("procedure", value);
                } else {
                  prev.delete("procedure");
                }
                return prev;
              });
            }}
          />

          <DocumentLabelsFilter
            selectedValue={
              searchParams.has("labels")
                ? searchParams.get("labels")?.split(",") ?? []
                : null
            }
            onChange={(value) => {
              setSearchParams((prev) => {
                if (value) {
                  prev.set("labels", value.join(","));
                } else {
                  prev.delete("labels");
                }
                return prev;
              });
            }}
          />

          {showAuthor && (
            <DocumentAuthorFilter
              selectedValue={
                searchParams.has("author") ? searchParams.get("author") : null
              }
              onChange={(value) => {
                setSearchParams((prev) => {
                  if (value) {
                    prev.set("author", `${value.firstName} ${value.lastName}`);
                  } else {
                    prev.delete("author");
                  }
                  return prev;
                });
              }}
            />
          )}
        </div>

        <div>
          <CreateTransactionButton />
        </div>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>

          {isLoading ? (
            <TableBody>
              {Array.from({ length: 10 }).map((_, idx) => (
                <TableRow key={idx}>
                  {table.getAllColumns().map((col) => {
                    return (
                      <TableCell key={`${col.id}-${idx}`}>
                        <Skeleton className="h-5" />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    Sin resultados
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </div>

      {!isLoading && <DataTablePagination table={table} />}
    </div>
  );
};

export { DocumentsList };
