import { ProcedureTypeEnum, TDocumentItem } from "@/api/v2/documents";
import { DotIcon } from "lucide-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useCurrentStep } from "@/hooks/transactions-hooks";
import { cn } from "@/lib/utils";
import { useMemo } from "react";

type StepElementProps = {
  label: string;
  step: string;
  currentDocument?: TDocumentItem;
  disabled?: boolean;
};

const StepElement = ({
  label,
  step,
  currentDocument,
  disabled,
}: StepElementProps) => {
  const currentStep = useCurrentStep();
  const isActive = currentStep === step;
  const Wrapper = isActive ? BreadcrumbPage : BreadcrumbLink;

  return (
    <BreadcrumbItem>
      {disabled ? (
        <span>{label}</span>
      ) : (
        <Wrapper
          className={cn(
            "border-b-2 border-transparent px-2 py-1",
            isActive && "border-primary",
          )}
          to={`/transactions/${currentDocument?.transactionId}/documents/${currentDocument?.code}/${step}`}
        >
          {label}
        </Wrapper>
      )}
    </BreadcrumbItem>
  );
};

const DocumentCreateSteps = ({
  currentDocument,
}: {
  currentDocument?: TDocumentItem;
}) => {
  const signaturePositioning = useMemo(
    () => currentDocument?.procedureType === ProcedureTypeEnum.CERTIFY,
    [currentDocument?.procedureType],
  );

  const notarySelection = useMemo(
    () =>
      currentDocument?.procedureType === ProcedureTypeEnum.CERTIFY ||
      currentDocument?.procedureType === ProcedureTypeEnum.AUTHORIZE,
    [currentDocument?.procedureType],
  );

  return (
    <Breadcrumb>
      <BreadcrumbList className="justify-center">
        <StepElement
          label="Documento"
          step="new"
          currentDocument={currentDocument}
        />

        <BreadcrumbSeparator>
          <DotIcon />
        </BreadcrumbSeparator>

        <StepElement
          label="Firmantes"
          step="signees"
          currentDocument={currentDocument}
          disabled={!currentDocument?.subcategory}
        />

        {signaturePositioning && (
          <>
            <BreadcrumbSeparator>
              <DotIcon />
            </BreadcrumbSeparator>

            <StepElement
              label="Posicionar Firmas"
              step="signatures"
              currentDocument={currentDocument}
              disabled={!currentDocument?.subcategory}
            />
          </>
        )}

        <BreadcrumbSeparator>
          <DotIcon />
        </BreadcrumbSeparator>

        <StepElement
          label="Anexos"
          step="attachments"
          currentDocument={currentDocument}
          disabled={!currentDocument?.subcategory}
        />

        {notarySelection && (
          <>
            <BreadcrumbSeparator>
              <DotIcon />
            </BreadcrumbSeparator>

            <StepElement
              label="Notaría"
              step="notary"
              currentDocument={currentDocument}
              disabled={!currentDocument?.subcategory}
            />
          </>
        )}

        <BreadcrumbSeparator>
          <DotIcon />
        </BreadcrumbSeparator>

        <StepElement
          label="Resumen"
          step="summary"
          currentDocument={currentDocument}
          disabled={!currentDocument?.subcategory}
        />
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export { DocumentCreateSteps };
