import { cn } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import { ElementRef, useMemo, useRef, useState } from "react";
import { Document as PDFDocument, Thumbnail } from "react-pdf";
import { PDFViewer } from "../PDF/PDFViewer";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Skeleton } from "../ui/skeleton";
import { getDocumentFileURL } from "@/api/v2/documents";

const DocumentPreview = ({
  documentCode,
  fileType = "INITIAL_DOCUMENT",
  height,
}: {
  documentCode: string;
  fileType?:
    | "INITIAL_DOCUMENT"
    | "INITIAL_DOCUMENT_WITH_MANDATES"
    | "SIGNED_DOCUMENT";
  height: number;
}) => {
  const [open, setOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const wrapperRef = useRef<ElementRef<"div">>(null);

  const query = useQuery({
    queryKey: ["getDocumentFile", { documentCode, fileType }],
    queryFn: () =>
      getDocumentFileURL({ code: documentCode, fileKind: fileType }),
    refetchOnWindowFocus: false,
  });

  const fileUrl = useMemo(() => query.data?.download_url, [query.data]);

  const wrapperRect = wrapperRef.current?.getBoundingClientRect();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <div
          className="relative overflow-hidden rounded-lg border shadow-sm"
          style={{ height: `${height}rem` }}
          ref={wrapperRef}
        >
          {isReady && (
            <div
              className={cn(
                "absolute z-10 h-full w-full cursor-pointer bg-transparent transition-all hover:bg-black/60",
                "flex items-center justify-center text-transparent hover:text-white",
              )}
            >
              Click para visualizar
            </div>
          )}

          {fileUrl && (
            <PDFDocument
              file={fileUrl}
              className={cn("w-full")}
              loading={
                <Skeleton
                  className="flex items-center justify-center"
                  style={{ height: `${height}rem` }}
                >
                  Cargando...
                </Skeleton>
              }
              onLoadSuccess={() => setIsReady(true)}
              onItemClick={() => setOpen(true)}
            >
              <Thumbnail
                className={cn("flex w-full items-center justify-center")}
                width={wrapperRect?.width ?? 0}
                height={wrapperRect?.height ?? 0}
                pageNumber={1}
              />
            </PDFDocument>
          )}
        </div>
      </DialogTrigger>

      <DialogContent className="flex h-[90%] min-w-[95%] flex-col lg:min-w-[1000px]">
        <DialogHeader>
          <DialogTitle>Documento original</DialogTitle>
        </DialogHeader>

        <div className="h-full overflow-hidden">
          {fileUrl && (
            <PDFViewer
              fileUrl={fileUrl}
              className="h-full w-full text-center"
              onLoadError={() => query.refetch()}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { DocumentPreview };
