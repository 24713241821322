import { getDocumentFileURL } from "@/api/v2/documents";
import { PDFViewer } from "@/components/PDF/PDFViewer";
import { DocumentForm } from "@/components/Transactions/DocumentForm";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { useCurrentDocument } from "@/context/current-document";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const TransactionDocumentPage = () => {
  const { currentDocument, isLoading, refetch } = useCurrentDocument();

  const navigate = useNavigate();

  const query = useQuery({
    queryKey: ["getInitialDocument", { code: currentDocument?.code }],
    queryFn: () =>
      getDocumentFileURL({
        code: currentDocument?.code as string,
        fileKind: "INITIAL_DOCUMENT",
      }),
    refetchOnWindowFocus: false,
    enabled: !!currentDocument,
  });

  const fileUrl = useMemo(() => query.data?.download_url, [query.data]);

  if (isLoading || !currentDocument) {
    return <Skeleton className="h-96 w-full" />;
  }

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-8 min-h-[1280px] rounded-lg border">
        {fileUrl && (
          <PDFViewer fileUrl={fileUrl} className="h-full text-center" />
        )}
      </div>

      <div className="col-span-4">
        <Card>
          <CardHeader>
            <CardTitle>Detalle del documento</CardTitle>
          </CardHeader>

          <Separator />

          <CardContent className="pt-6">
            <DocumentForm
              currentDocument={currentDocument}
              onSave={(data) => {
                refetch();
                navigate(
                  `/transactions/${data.transactionId}/documents/${data.code}/signees`,
                );
              }}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export { TransactionDocumentPage };
