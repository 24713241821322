import { TDocumentItem } from "@/api/v2/documents";
import { createSignee } from "@/api/v2/signees";
import { Button } from "@/components/ui/button";
import { SigneeTypeRepresentation } from "@/enums/SigneeTypeRepresentation.enum";
import { cn } from "@/lib/utils";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Loader2Icon, PlusIcon, SparklesIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Checkbox } from "../ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { Separator } from "../ui/separator";

import idCardImageBase from "@/assets/images/carnet.jpg";
import idCardImageMaternalLastName from "@/assets/images/carnetApellidoMaterno.jpg";
import idCardImagePaternalLastName from "@/assets/images/carnetApellidoPaterno.jpg";
import idCardImageNames from "@/assets/images/carnetNombres.jpg";
import idCardImageRUN from "@/assets/images/carnetRUT.jpg";
import { AlertError } from "../ui/alert-error";
import { useAppStore } from "@/stores/AppStore";
import { listLegalEntityRepresentatives } from "@/api/v2/legal-entities";

const formSchema = z
  .object({
    documentNumber: z
      .string({
        required_error: "RUN es requerido",
      })
      .min(1, "RUN es requerido"),
    firstName: z
      .string({
        required_error: "Nombres es requerido",
      })
      .min(1, "Nombre es requerido"),
    lastName: z
      .string({
        required_error: "Apellido paterno es requerido",
      })
      .min(1, "Apellido paterno es requerido"),
    maternalLastName: z
      .string({
        required_error: "Apellido materno es requerido",
      })
      .min(1, "Apellido materno es requerido"),
    phoneNumber: z.string().nullable(),
    email: z
      .string({
        required_error: "Email es requerido",
      })
      .email("Correo electrónico ingresado no es válido"),
    isRepresentative: z.boolean().default(false),
    inRepresentationOf: z
      .object({
        name: z.string(),
        documentNumber: z.string(),
        type: z.nativeEnum(SigneeTypeRepresentation),
      })
      .nullable(),
  })
  .superRefine((data, ctx) => {
    if (!data.isRepresentative) {
      return;
    }

    if (data.inRepresentationOf?.name.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        path: ["inRepresentationOf", "name"],
        minimum: 1,
        inclusive: true,
        type: "string",
        message:
          data.inRepresentationOf.type === SigneeTypeRepresentation.Legal
            ? "Razón social es requerida"
            : "Nombre completo es requerido",
      });
    }

    if (data.inRepresentationOf?.documentNumber.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        path: ["inRepresentationOf", "documentNumber"],
        minimum: 1,
        inclusive: true,
        type: "string",
        message:
          data.inRepresentationOf.type === SigneeTypeRepresentation.Legal
            ? "RUT es requerido"
            : "RUN es requerido",
      });
    }
  });

type TSigneeForm = z.infer<typeof formSchema>;

type SigneeFormProps = {
  currentDocument?: TDocumentItem;
  onSave: () => void;
};

type TRecommendedSignee = {
  firstName: string;
  lastName: string;
  maternalLastName: string;
  email: string;
  documentNumber: string;
  phoneNumber: string | null;
  isRepresentative: boolean;
  inRepresentationOf?: {
    name: string;
    documentNumber: string;
    type: SigneeTypeRepresentation;
  };
};

const RecommendedSignees = ({
  onSelect,
}: {
  onSelect: (signee: TRecommendedSignee) => void;
}) => {
  const { legalEntity } = useAppStore();

  const query = useQuery({
    queryKey: ["listRepresentatives", { legalEntityId: legalEntity?.id }],
    queryFn: () =>
      listLegalEntityRepresentatives({
        legalEntityId: legalEntity?.id as string,
      }),
    enabled: !!legalEntity && legalEntity.personType === "JURIDICAL",
  });

  const items = query.data ?? [];

  if (items.length === 0) {
    return null;
  }

  return (
    <div className="col-span-12">
      <div
        className={cn(
          "flex flex-col gap-4 rounded-lg border border-sky-200 bg-sky-50 p-4 text-sm",
          "dark:border-accent dark:bg-accent",
          "mb-3",
        )}
      >
        <div className="flex items-center gap-3">
          <SparklesIcon size={18} />
          <strong>Firmantes Recomendados</strong>
        </div>

        <div className="flex flex-wrap items-center gap-3">
          {items.map((item) => (
            <div
              key={item.documentNumber}
              className={cn(
                "bg-blue-100 hover:bg-blue-100/60",
                "border border-sky-200",
                "dark:border-zinc-500 dark:bg-zinc-800 dark:hover:bg-zinc-700",
                "flex items-center",
                "cursor-pointer rounded px-3 py-2",
              )}
              onClick={() =>
                legalEntity &&
                onSelect({
                  ...item,
                  maternalLastName: item.maternalLastName ?? "",
                  phoneNumber: item.phoneNumber ?? "",
                  isRepresentative: true,
                  inRepresentationOf: {
                    name: legalEntity.name,
                    documentNumber: legalEntity.documentNumber,
                    type: SigneeTypeRepresentation.Legal,
                  },
                })
              }
            >
              <PlusIcon size={18} className="mr-1" />
              {item.firstName} {item.lastName}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const SigneeForm = ({ currentDocument, onSave }: SigneeFormProps) => {
  const [wrapperParent] = useAutoAnimate();
  const [parent] = useAutoAnimate();
  const [idCardImage, setIdCardImage] = useState(idCardImageBase);

  const mutation = useMutation({
    mutationKey: ["createSignee"],
    mutationFn: createSignee,
  });

  const defaultValues: TSigneeForm = {
    firstName: "",
    lastName: "",
    maternalLastName: "",
    email: "",
    phoneNumber: "",
    documentNumber: "",
    isRepresentative: false,
    inRepresentationOf: {
      type: SigneeTypeRepresentation.Legal,
      name: "",
      documentNumber: "",
    },
  };

  const form = useForm<TSigneeForm>({
    resolver: zodResolver(formSchema),
    disabled: mutation.isPending,
    defaultValues,
  });

  const watchIsRepresentative = form.watch("isRepresentative");
  const watchRepresentativeType = form.watch("inRepresentationOf.type");

  return (
    <div className="grid grid-cols-12 items-start gap-4" ref={wrapperParent}>
      <RecommendedSignees onSelect={(signee) => form.reset(signee)} />

      <div className="col-span-7">
        <Form {...form}>
          <form
            ref={parent}
            className="flex flex-col gap-4"
            onSubmit={form.handleSubmit((data) => {
              if (!currentDocument) {
                return;
              }

              if (!data.isRepresentative) {
                data.inRepresentationOf = null;
              }

              mutation.mutate(
                {
                  documentCode: currentDocument?.code,
                  ...data,
                },
                {
                  onSuccess: () => {
                    mutation.reset();
                    form.reset(defaultValues);
                    onSave();
                  },
                },
              );
            })}
          >
            <div className="flex flex-row gap-4">
              <FormField
                name="documentNumber"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>RUN</FormLabel>
                    <FormControl>
                      <Input
                        autoFocus
                        type="text"
                        placeholder="RUN sin puntos y con guión (12749625-K)"
                        onFocus={() => setIdCardImage(idCardImageRUN)}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="firstName"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Nombres</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        {...field}
                        onFocus={() => setIdCardImage(idCardImageNames)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-row gap-4">
              <FormField
                name="lastName"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Apellido paterno</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        {...field}
                        onFocus={() =>
                          setIdCardImage(idCardImagePaternalLastName)
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="maternalLastName"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Apellido materno</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        {...field}
                        onFocus={() =>
                          setIdCardImage(idCardImageMaternalLastName)
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-row gap-4">
              <FormField
                name="email"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        {...field}
                        onFocus={() => setIdCardImage(idCardImageBase)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Número de teléfono (Opcional)</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        {...field}
                        onFocus={() => setIdCardImage(idCardImageBase)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              name="isRepresentative"
              control={form.control}
              render={({ field }) => (
                <label
                  htmlFor="isRepresentative"
                  className={cn("flex flex-col", "cursor-pointer py-2")}
                >
                  <div className="flex flex-row items-center gap-3">
                    <Checkbox
                      id="isRepresentative"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <div className="text-sm">
                      ¿Firmar en representación de otra persona o empresa?
                    </div>
                  </div>
                </label>
              )}
            />

            {watchIsRepresentative && (
              <>
                <Separator />

                <FormField
                  name="inRepresentationOf.type"
                  control={form.control}
                  render={({ field }) => (
                    <RadioGroup
                      className="flex flex-col gap-4"
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <Label
                        htmlFor={SigneeTypeRepresentation.Legal}
                        className={cn(
                          "flex w-full flex-col",
                          "cursor-pointer rounded-md border p-6",
                          "hover:bg-accent",
                        )}
                      >
                        <div className="flex flex-row gap-3">
                          <RadioGroupItem
                            id={SigneeTypeRepresentation.Legal}
                            value={SigneeTypeRepresentation.Legal}
                          />
                          <div className="pt-0.5 font-bold">
                            Representa a un Persona Jurídica
                          </div>
                        </div>

                        <FormDescription className="pl-7 pt-2">
                          El firmante firma como representante legal de una
                          sociedad o empresa.
                        </FormDescription>
                      </Label>

                      <Label
                        htmlFor={SigneeTypeRepresentation.Natural}
                        className={cn(
                          "flex w-full flex-col",
                          "cursor-pointer rounded-md border p-6",
                          "hover:bg-accent",
                        )}
                      >
                        <div className="flex flex-row gap-3">
                          <RadioGroupItem
                            id={SigneeTypeRepresentation.Natural}
                            value={SigneeTypeRepresentation.Natural}
                          />
                          <div className="pt-0.5 font-bold">
                            Representa a una Persona Natural
                          </div>
                        </div>

                        <FormDescription className="pl-7 pt-2">
                          El firmante firma en representación de otra persona
                        </FormDescription>
                      </Label>
                    </RadioGroup>
                  )}
                />

                <div className="flex flex-row gap-4">
                  <FormField
                    name="inRepresentationOf.documentNumber"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          {watchRepresentativeType ===
                          SigneeTypeRepresentation.Legal
                            ? "RUT"
                            : "RUN"}
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            {...field}
                            placeholder="RUN sin puntos y con guión (12749625-K)"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    name="inRepresentationOf.name"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          {watchRepresentativeType ===
                          SigneeTypeRepresentation.Legal
                            ? "Razón Social"
                            : "Nombre completo"}
                        </FormLabel>
                        <FormControl>
                          <Input type="text" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </>
            )}

            {mutation.isError && <AlertError error={mutation.error} />}

            <div className=" flex flex-row gap-4">
              <Button type="submit" disabled={mutation.isPending}>
                {mutation.isPending ? (
                  <>
                    <Loader2Icon className="mr-2 animate-spin" />
                    <span>Creando firmante...</span>
                  </>
                ) : (
                  <span>Agregar firmante</span>
                )}
              </Button>
            </div>
          </form>
        </Form>
      </div>

      <div className="col-span-5">
        <img
          src={idCardImage}
          alt="Cédula de identidad chilena"
          className="rounded-2xl"
        />
      </div>
    </div>
  );
};

export { SigneeForm };
