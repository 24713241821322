import { TDocumentItem } from "@/api/v2/documents";
import { getNotaries } from "@/api/v2/notaries";
import {
  TTransactionItem,
  getTransactionById,
  updateTransaction,
} from "@/api/v2/transactions";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Separator } from "@/components/ui/separator";
import { useCurrentDocument } from "@/context/current-document";
import { cn } from "@/lib/utils";
import { useMutation, useQuery } from "@tanstack/react-query";

const NotarySelector = ({
  currentDocument,
  transaction,
}: {
  currentDocument: TDocumentItem;
  transaction: TTransactionItem;
}) => {
  const query = useQuery({
    queryKey: ["listNotaries"],
    queryFn: () => getNotaries(),
  });

  const mutation = useMutation({
    mutationKey: [
      "updateTransaction",
      { transactionId: currentDocument?.transactionId },
    ],
    mutationFn: updateTransaction,
  });

  const notaries = query.data ?? [];
  const currentNotaryId = transaction.notary?.id;

  return (
    <RadioGroup
      defaultValue={currentNotaryId}
      onValueChange={(value) => {
        if (!currentDocument) return;
        mutation.mutate({
          transactionId: currentDocument.transactionId,
          notaryId: value,
        });
      }}
    >
      {notaries.map((item) => (
        <Label
          htmlFor={item.id}
          key={item.id}
          className={cn(
            "flex flex-col",
            "cursor-pointer rounded-md border p-6",
            "bg-accent",
          )}
        >
          <div className="flex flex-row gap-3">
            <RadioGroupItem id={item.id} value={item.id} />

            <div className="pt-0.5 font-bold">
              <div>{item.name}</div>
            </div>
          </div>
        </Label>
      ))}
    </RadioGroup>
  );
};

const TransactionNotaryPage = () => {
  const { currentDocument } = useCurrentDocument();

  const query = useQuery({
    queryKey: ["getTransaction", { id: currentDocument?.transactionId }],
    queryFn: () => getTransactionById(currentDocument?.transactionId as string),
    enabled: !!currentDocument,
  });

  const transaction = query.data;

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Seleccionar Notaría</CardTitle>
        </CardHeader>

        <Separator className="mb-6" />

        <CardContent>
          {currentDocument && transaction && (
            <NotarySelector
              currentDocument={currentDocument}
              transaction={transaction}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export { TransactionNotaryPage };
