import {
  createSignature,
  deleteSignature,
  getDocumentFileURL,
  TDocumenSignee,
  updateSignature,
} from "@/api/v2/documents";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { useCurrentDocument } from "@/context/current-document";
import { useTransactionNextStep } from "@/hooks/transactions-hooks";
import { cn } from "@/lib/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GripVerticalIcon, XIcon } from "lucide-react";
import React, {
  createRef,
  ElementRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { Page, Document as PDFDocument } from "react-pdf";

const SIGNATURE_BOX_WIDTH = 100;
const SIGNATURE_BOX_HEIGHT = 40;

type Point = {
  x: number;
  y: number;
};

type PDFPoint = Point & {
  tx: number;
  ty: number;
  page: number;
};

type SigneePlacement = {
  signee: TDocumenSignee;
  coords: Point;
};

type SignaturePlaceholder = {
  uid: string;
  point: Point;
  page: number;
  signee: TDocumenSignee;
};

type SignatureBoxProps = Omit<SignaturePlaceholder, "uid" | "page"> & {
  uid?: string;
  code?: string;
  documentCode: string;
  pageSize: { width?: number; height?: number };
  onRemove?: () => void;
  onUpdate?: () => void;
  getPDFCoordinates?: (point: Point) => PDFPoint | undefined;
};

const renderSigneeInitials = (signee: TDocumenSignee) => {
  const maternalLastNameInitial = signee.maternalLastName
    ? "." + signee.maternalLastName?.charAt(0)
    : "";
  return `${signee.firstName.charAt(0)}.${signee.lastName.charAt(0)}${maternalLastNameInitial}`.toUpperCase();
};

const SignatureBox = ({
  documentCode,
  code,
  point,
  pageSize,
  signee,
  onRemove,
  onUpdate,
  getPDFCoordinates,
}: SignatureBoxProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const draggedRef = useRef<ElementRef<"div">>(null);

  const [position, setPosition] = useState<Point>({
    x: point.x,
    y: point.y - (pageSize?.height ?? 0),
  });

  const updateMutation = useMutation({
    mutationKey: ["updateSignaturePosition", { documentCode, code }],
    mutationFn: updateSignature,
    onSuccess: () => onUpdate && onUpdate(),
  });

  const deleteMutation = useMutation({
    mutationKey: ["deleteSignature", { documentCode, code }],
    mutationFn: deleteSignature,
    onSuccess: () => onRemove && onRemove(),
  });

  const isLoading =
    !code || deleteMutation.isPending || updateMutation.isPending;

  const onDragStart = useCallback(() => {
    setIsDragging(true);
  }, [setIsDragging]);

  const onDragStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      setIsDragging(false);

      if (!code || !getPDFCoordinates) return;
      const ev = e as MouseEvent;
      const rect = (e.target as HTMLDivElement).getBoundingClientRect();

      const dropPoint: Point = {
        x: ev.clientX - (ev.clientX - rect.left),
        y: ev.clientY - (ev.clientY - rect.top),
      };

      const coords = getPDFCoordinates(dropPoint);
      if (!coords) {
        return setPosition({
          x: point.x,
          y: point.y - (pageSize?.height ?? 0),
        });
      }
      setPosition({ x: data.x, y: data.y });

      updateMutation.mutate({
        code,
        documentCode,
        page: coords.page,
        x: coords.tx,
        y: coords.ty,
      });
    },
    [
      code,
      documentCode,
      getPDFCoordinates,
      pageSize?.height,
      point.x,
      point.y,
      updateMutation,
    ],
  );

  return (
    <Draggable
      nodeRef={draggedRef}
      position={position}
      onStart={onDragStart}
      onStop={onDragStop}
    >
      <div
        ref={draggedRef}
        className={cn(
          "group absolute z-[51] h-full w-full px-4 py-2",
          "flex items-center justify-center text-xs text-emerald-700 dark:text-emerald-900",
          "select-none text-center font-bold",
          "border-2 border-primary bg-primary/50 shadow",
          isLoading
            ? "animate-pulse cursor-wait border-slate-600 bg-slate-500/30 text-gray-900"
            : "cursor-grab",
          isDragging && "cursor-grabbing",
        )}
        style={{
          width: SIGNATURE_BOX_WIDTH,
          height: SIGNATURE_BOX_HEIGHT,
          zIndex: 51,
        }}
      >
        <span>{renderSigneeInitials(signee)}</span>
        <div
          onClick={() => code && deleteMutation.mutate({ documentCode, code })}
          className={cn(
            "scale-75 transition-all",
            "hover:scale-100 ",
            "flex cursor-pointer items-center justify-center text-white",
            "absolute -right-3 -top-3 h-6 w-6 rounded-full bg-rose-500",
            "hidden group-hover:flex",
          )}
        >
          <XIcon size={12} />
        </div>
      </div>
    </Draggable>
  );
};

const DocumentViewer = ({
  signaturePlacement,
}: {
  signaturePlacement: SigneePlacement[];
}) => {
  const { currentDocument, refetch } = useCurrentDocument();
  const viewerRef = useRef<ElementRef<"div">>(null);

  const [placeholders, setPlaceholders] = useState<SignaturePlaceholder[]>([]);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageRefs, setPageRefs] = useState<React.RefObject<HTMLDivElement>[]>(
    [],
  );

  const [pageSizes, setPageSizes] = useState<
    { width: number; height: number }[]
  >([]);

  const query = useQuery({
    queryKey: ["getInitialDocument", { code: currentDocument?.code }],
    queryFn: () =>
      getDocumentFileURL({
        code: currentDocument?.code as string,
        fileKind: "INITIAL_DOCUMENT",
      }),
    refetchOnWindowFocus: false,
    enabled: !!currentDocument,
  });

  const mutation = useMutation({
    mutationKey: ["createSignature"],
    mutationFn: createSignature,
    onSuccess(_, variables) {
      refetch();
      setPlaceholders((prev) =>
        prev.filter(
          (x) =>
            x.uid !== `${variables.privateCode}-${variables.x}-${variables.y}`,
        ),
      );
    },
  });

  const getPDFCoordinates = useCallback(
    (point: Point) => {
      if (!viewerRef.current) {
        return undefined;
      }

      const boundsRect = viewerRef.current.getBoundingClientRect();

      // Check if it's inside document on x axis
      const isOutside =
        point.x < boundsRect.left ||
        point.x + SIGNATURE_BOX_WIDTH > boundsRect.right;

      if (isOutside) return; // Return if placeholder was dropped outside

      for (let i = 0; i < pageRefs.length; i++) {
        const pageElement = pageRefs[i]?.current;
        if (!pageElement) continue;

        // Get page boundaries
        const pageRect = pageElement.getBoundingClientRect();
        const pageHeight = pageRect.height;
        const pageWidth = pageRect.width;

        const offsetTop = point.y - pageRect.top + viewerRef.current.scrollTop;
        const offsetBottom = pageHeight - (offsetTop + SIGNATURE_BOX_HEIGHT);
        const offsetLeft = point.x - pageRect.left;

        const isInsidePageX =
          point.x > pageRect.left &&
          point.x + SIGNATURE_BOX_WIDTH < pageRect.right;

        const isInsidePageY = offsetTop > 0 && offsetBottom > 0;

        if (!isInsidePageX || !isInsidePageY) {
          continue;
        }

        const page = i + 1;

        const x = offsetLeft;
        const y = offsetTop;

        return {
          x,
          y,
          page,
          tx: x / pageWidth,
          ty: y / pageHeight,
        } as PDFPoint;
      }
    },
    [viewerRef, pageRefs],
  );

  useEffect(() => {
    if (!currentDocument || signaturePlacement.length === 0) {
      return undefined;
    }

    const placement = signaturePlacement.pop();
    if (!placement) return;

    const coords = getPDFCoordinates({
      x: placement.coords.x,
      y: placement.coords.y,
    });
    if (!coords) return;

    setPlaceholders((prev) => [
      ...prev,
      {
        uid: `${placement.signee.privateCode}-${coords.tx}-${coords.ty}`,
        signee: placement.signee,
        page: coords.page,
        point: {
          x: coords.x,
          y: coords.y,
        },
      },
    ]);

    mutation.mutate({
      page: coords.page,
      x: coords.tx,
      y: coords.ty,
      privateCode: placement.signee.privateCode,
      documentCode: currentDocument.code,
    });
  }, [
    signaturePlacement,
    viewerRef,
    pageRefs,
    currentDocument,
    getPDFCoordinates,
    mutation,
  ]);

  const fileUrl = useMemo(() => query.data?.download_url, [query.data]);

  return (
    <div className="flex w-full flex-col">
      {currentDocument && fileUrl && (
        <PDFDocument
          file={fileUrl}
          inputRef={viewerRef}
          loading={
            <Skeleton className="flex h-[400px] w-full items-center justify-center">
              Cargando...
            </Skeleton>
          }
          className={cn(
            "w-full rounded-lg border bg-secondary p-10 text-center shadow",
          )}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);

            setPageRefs(
              Array(numPages)
                .fill(null)
                .map(() => createRef<HTMLDivElement>()),
            );
          }}
        >
          {Array.from(new Array(numPages), (_, index) => (
            <div key={`wrapper_${index + 1}`} className="w-full">
              <Page
                key={`page_${index + 1}`}
                inputRef={pageRefs[index]}
                className="mb-2 inline-block border shadow"
                pageNumber={index + 1}
                loading={
                  <Skeleton className="flex h-[400px] w-full items-center justify-center">
                    Cargando...
                  </Skeleton>
                }
                onRenderSuccess={() => {
                  const pageRef = pageRefs[index];

                  if (pageRef.current) {
                    const rect = pageRef.current.getBoundingClientRect();
                    setPageSizes((prevSizes) => {
                      const newSizes = [...prevSizes];
                      newSizes[index] = {
                        width: rect.width,
                        height: rect.height,
                      };
                      return newSizes;
                    });
                  }
                }}
              >
                {pageSizes &&
                  placeholders.map((placeholder) => {
                    if (placeholder.page !== index + 1) {
                      return null;
                    }

                    return (
                      <SignatureBox
                        key={placeholder.uid}
                        uid={placeholder.uid}
                        point={placeholder.point}
                        signee={placeholder.signee}
                        pageSize={pageSizes[index]}
                        documentCode={currentDocument?.code}
                      />
                    );
                  })}

                {pageSizes[index] &&
                  currentDocument?.signees.map((signee) => (
                    <React.Fragment key={signee.privateCode}>
                      {signee.coordinatesPdf.map((signature) => {
                        const pageSize = pageSizes[index];
                        if (signature.page !== index + 1) {
                          return null;
                        }

                        return (
                          <SignatureBox
                            key={signature.code}
                            code={signature.code}
                            documentCode={currentDocument?.code}
                            signee={signee}
                            point={{
                              x: signature.x * pageSize.width,
                              y: signature.y * pageSize.height,
                            }}
                            pageSize={pageSizes[index]}
                            onRemove={refetch}
                            onUpdate={refetch}
                            getPDFCoordinates={getPDFCoordinates}
                          />
                        );
                      })}
                    </React.Fragment>
                  ))}
              </Page>
            </div>
          ))}
        </PDFDocument>
      )}
    </div>
  );
};

const SigneeListItem = ({
  signee,
  onDrop,
}: {
  signee: TDocumenSignee;
  onDrop: (e: DraggableEvent, data: DraggableData) => void;
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const draggedRef = useRef<ElementRef<"div">>(null);

  const signeeInitials = useMemo(() => renderSigneeInitials(signee), [signee]);

  const onDragStart = useCallback((e: DraggableEvent) => {
    setIsDragging(true);
    const ev = e as MouseEvent;

    if (draggedRef.current) {
      const bounds = draggedRef.current.getBoundingClientRect();

      setPosition({
        x: ev.clientX - bounds.x,
        y: ev.clientY - bounds.y,
      });
    }
  }, []);

  const onDragStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      setPosition({ x: 0, y: 0 });
      setIsDragging(false);
      onDrop(e, data);
    },
    [onDrop],
  );

  return (
    <div className="group/item relative">
      <div
        className={cn(
          "flex items-center justify-between border-t",
          "group-last/item:rounded-b-lg ",
          "group-hover/item:bg-secondary",
          "cursor-grab px-6 py-4",
          isDragging && "bg-secondary",
        )}
      >
        <span>
          {signee.firstName} {signee.lastName} {signee.maternalLastName}
        </span>

        <div className="flex gap-2">
          {signee.coordinatesPdf.length > 0 && (
            <Badge>{signee.coordinatesPdf.length}</Badge>
          )}
          <GripVerticalIcon />
        </div>
      </div>

      <Draggable
        position={position}
        nodeRef={draggedRef}
        onStart={onDragStart}
        onStop={onDragStop}
      >
        <div
          ref={draggedRef}
          className={cn(
            "absolute cursor-grab border-transparent bg-transparent text-transparent",
            "top-0 z-[51] cursor-grab border-2 px-6 py-4",
            "h-full w-full select-none text-center font-bold",
            "flex items-center justify-center",
            isDragging &&
              cn(
                "cursor-grabbing border-primary bg-primary/50",
                "px-4 py-2 text-xs text-emerald-700 shadow dark:text-emerald-900",
              ),
          )}
          style={{
            width: isDragging ? SIGNATURE_BOX_WIDTH : undefined,
            height: isDragging ? SIGNATURE_BOX_HEIGHT : undefined,
          }}
        >
          {signeeInitials}
        </div>
      </Draggable>
    </div>
  );
};

const SigneesList = ({
  onSigneeDrop,
}: {
  onSigneeDrop: (signee: TDocumenSignee, e: MouseEvent) => void;
}) => {
  const { currentDocument } = useCurrentDocument();

  return (
    <div>
      {currentDocument?.signees.map((item) => (
        <SigneeListItem
          key={item.privateCode}
          signee={item}
          onDrop={(e) => onSigneeDrop(item, e as MouseEvent)}
        />
      ))}
    </div>
  );
};

const AddSignaturesPage = () => {
  const { currentDocument } = useCurrentDocument();
  const { nextStepHandler } = useTransactionNextStep(currentDocument);
  const signeesWrapperRef = useRef<ElementRef<"div">>(null);
  const [signaturePlacement, setSignaturePlacement] = useState<
    SigneePlacement[]
  >([]);

  useEffect(() => {
    const el = signeesWrapperRef.current;
    const observer = new IntersectionObserver(
      ([e]) => el?.classList.toggle("is-pinned", e.intersectionRatio < 1),
      {
        rootMargin: "-22px 0px 0px 0px",
        threshold: [1],
      },
    );

    if (el) {
      observer.observe(signeesWrapperRef.current);
    }

    return () => {
      el ? observer.unobserve(el) : undefined;
    };
  }, []);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="z-50 col-span-4">
        <div ref={signeesWrapperRef} className="group sticky top-5">
          <Card>
            <CardHeader>
              <CardTitle>Firmantes</CardTitle>
              <CardDescription>
                Selecciona y arrastra cada firmante para posicionar su firma en
                el documento. Puedes agregarlo más de una vez.
              </CardDescription>
            </CardHeader>

            <SigneesList
              onSigneeDrop={(signee, e) => {
                setSignaturePlacement((prev) => [
                  ...prev,
                  {
                    signee,
                    coords: {
                      x: e.clientX,
                      y: e.clientY,
                    },
                  },
                ]);
              }}
            />
          </Card>

          <div
            className={cn(
              "invisible opacity-0 transition-opacity duration-500",
              "group-[.is-pinned]:visible group-[.is-pinned]:opacity-100",
            )}
          >
            <Button className="mt-4 w-full" onClick={nextStepHandler}>
              Continuar
            </Button>
          </div>
        </div>
      </div>

      <div className="col-span-8">
        <DocumentViewer signaturePlacement={signaturePlacement} />
      </div>
    </div>
  );
};

export { AddSignaturesPage };
