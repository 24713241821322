import { z } from "zod";
import { firmeClient } from "../utils";
import { TPagedResponse } from "@/types/pagination";

export const UserRoles = {
  ADMIN: "LEGAL_ENTITY_ADMINISTRATOR",
  ASSISTANT: "LEGAL_ENTITY_ASSISTANT",
  REPRESENTATIVE: "LEGAL_REPRESENTATIVE",
} as const;

const PersonTypes = ["JURIDICAL", "NATURAL"] as const;

export const UserRoleSchema = z.nativeEnum(UserRoles);

const legalEntityItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  documentNumber: z.string(),
  personType: z.enum(PersonTypes),
  hasPreference: z.boolean().default(false),
  isVerified: z.boolean().default(false),
  role: UserRoleSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
});

const LegalEntityUserItemSchema = z.object({
  subId: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  documentNumber: z.string(),
  role: UserRoleSchema,
});

const DocumentQuotaSchema = z.object({
  certified: z.number(),
  authorized: z.number(),
  protocolized: z.number(),
  simple: z.number(),
  fea: z.number(),
});

export type TLegalEntityItem = z.infer<typeof legalEntityItemSchema>;
export type TLegalEntitiesList = TPagedResponse<TLegalEntityItem>;
export type TLegalentityUserItem = z.infer<typeof LegalEntityUserItemSchema>;
export type TDocumentQuota = z.infer<typeof DocumentQuotaSchema>;
export type TUserRole = (typeof UserRoles)[keyof typeof UserRoles];

export const UserRoleTranslations: Record<TUserRole, string> = {
  [UserRoles.ADMIN]: "Administrador",
  [UserRoles.ASSISTANT]: "Asistente",
  [UserRoles.REPRESENTATIVE]: "Representante Legal",
} as const;

export type LegalRepresentativeItemResponse = {
  firstName: string;
  lastName: string;
  maternalLastName: string | null;
  email: string;
  documentNumber: string;
  phoneNumber?: string | null;
  country: string;
};

export const listLegalEntities = async () => {
  return firmeClient
    .get<TLegalEntitiesList>("/legal-entities")
    .then((res) => res.data);
};

export const listUsers = async ({
  legalEntityId,
}: {
  legalEntityId: string;
}) => {
  return await firmeClient
    .get<TLegalentityUserItem[]>(`/legal-entities/${legalEntityId}/users`)
    .then((res) => res.data);
};

export const addUser = async ({
  legalEntityId,
  ...data
}: {
  legalEntityId: string;
  documentNumber: string;
  email: string;
  role: string;
}) => {
  return await firmeClient
    .post(`/legal-entities/${legalEntityId}/users`, data)
    .then((res) => res.data);
};

export const addRepresentative = async ({
  legalEntityId,
  ...data
}: {
  legalEntityId: string;
  firstName: string;
  lastName: string;
  maternalLastName: string;
  documentNumber: string;
  email: string;
}) => {
  return await firmeClient
    .post(`/legal-entities/${legalEntityId}/representatives`, data)
    .then((res) => res.data);
};

export const listLabels = async ({
  legalEntityId,
}: {
  legalEntityId: string;
}) =>
  await firmeClient
    .get<string[]>(`/legal-entities/${legalEntityId}/labels`)
    .then((res) => res.data);

export const createLabel = async ({
  legalEntityId,
  name,
}: {
  legalEntityId: string;
  name: string;
}) =>
  await firmeClient
    .post<string[]>(`/legal-entities/${legalEntityId}/labels`, { name })
    .then((res) => res.data);

export const deleteLabel = async ({
  legalEntityId,
  name,
}: {
  legalEntityId: string;
  name: string;
}) =>
  await firmeClient
    .delete<
      string[]
    >(`/legal-entities/${legalEntityId}/labels/${encodeURIComponent(name)}`)
    .then((res) => res.data);

export const listLegalEntityRepresentatives = async ({
  legalEntityId,
}: {
  legalEntityId: string;
}) =>
  await firmeClient
    .get<
      LegalRepresentativeItemResponse[]
    >(`/legal-entities/${legalEntityId}/representatives`)
    .then((res) => res.data);

export const getQuota = async ({
  legalEntityId,
}: {
  legalEntityId: string;
}) => {
  return await firmeClient
    .get<TDocumentQuota>(`/legal-entities/${legalEntityId}/quota`)
    .then((res) => res.data);
};
