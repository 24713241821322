import {
  ProcedureTypeEnum,
  ProcedureTypeMapper,
  listCategories,
} from "@/api/v2/documents";
import {
  TLegalentityUserItem,
  listLabels,
  listUsers,
} from "@/api/v2/legal-entities";
import { cn } from "@/lib/utils";
import { useAppStore } from "@/stores/AppStore";
import { useQuery } from "@tanstack/react-query";
import { CheckIcon, PlusCircleIcon, XCircle } from "lucide-react";
import { useState } from "react";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Separator } from "../ui/separator";

type Status = {
  value: string;
  label: string;
};

type FilterProps<TValue> = {
  onChange: (value: TValue | null) => void;
  selectedValue: string | TValue | null;
};

const statuses: Status[] = [
  {
    value: "DRAFT",
    label: "Borrador",
  },
  {
    value: "PENDING_SIGNATURE",
    label: "Pendiente de firmas",
  },
  {
    value: "PENDING_NOTARY_SIGNATURE",
    label: "Firma de notario pendiente",
  },
  {
    value: "NOTARIZED",
    label: "Notarizado",
  },
];

const DocumentStatusFilter = ({
  selectedValue,
  onChange,
}: FilterProps<Status>) => {
  const [open, setOpen] = useState(false);
  const selectedStatus: Status | null =
    statuses.find((item) => item.value === selectedValue) || null;

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="outline" size="sm" className="h-10 border-dashed">
            <PlusCircleIcon className="mr-2 h-4 w-4" />
            <span>Estado</span>

            {selectedStatus && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />

                <Badge
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {selectedStatus.label}
                </Badge>
              </>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent className="p-0" side="bottom" align="start">
          <Command>
            <CommandInput placeholder="Filtrar estado" />
            <CommandList>
              <CommandEmpty>Sin resultados</CommandEmpty>

              {selectedStatus && (
                <>
                  <CommandGroup>
                    <CommandItem
                      className="cursor-pointer"
                      onSelect={() => {
                        onChange(null);
                        setOpen(false);
                      }}
                    >
                      <XCircle className={cn("mr-2 h-4 w-4")} />
                      <span>Quitar filtro</span>
                    </CommandItem>
                  </CommandGroup>
                  <CommandSeparator />
                </>
              )}

              <CommandGroup>
                {statuses.map((status) => (
                  <CommandItem
                    className="cursor-pointer"
                    key={status.value}
                    value={status.label}
                    onSelect={() => {
                      onChange(
                        statuses.find((item) => item.value === status.value) ||
                          null,
                      );
                      setOpen(false);
                    }}
                  >
                    <CheckIcon
                      className={cn(
                        "mr-2 h-4 w-4",
                        selectedStatus?.value !== status.value
                          ? "text-transparent"
                          : "",
                      )}
                    />
                    <span>{status.label}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const DocumentSubcategoryFilter = ({
  selectedValue,
  onChange,
}: FilterProps<string>) => {
  const [open, setOpen] = useState(false);

  const query = useQuery({
    queryKey: ["listCategories"],
    queryFn: listCategories,
  });

  const categories = query.data ?? [];

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="outline" size="sm" className="h-10 border-dashed">
            <PlusCircleIcon className="mr-2 h-4 w-4" />
            <span>Categoría</span>

            {selectedValue && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />

                <Badge
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {selectedValue}
                </Badge>
              </>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent className="p-0" side="bottom" align="start">
          <Command>
            <CommandInput placeholder="Filtrar categorías" />
            <CommandList>
              <CommandEmpty>Sin resultados</CommandEmpty>

              {selectedValue && (
                <>
                  <CommandGroup>
                    <CommandItem
                      className="cursor-pointer"
                      onSelect={() => {
                        onChange(null);
                        setOpen(false);
                      }}
                    >
                      <XCircle className={cn("mr-2 h-4 w-4")} />
                      <span>Quitar filtro</span>
                    </CommandItem>
                  </CommandGroup>
                  <CommandSeparator />
                </>
              )}

              <CommandGroup>
                {categories.map((item) => (
                  <CommandItem
                    className="flex cursor-pointer items-center"
                    key={item.name}
                    value={item.name}
                    onSelect={(value) => {
                      onChange(value);
                      setOpen(false);
                    }}
                  >
                    <div>
                      <CheckIcon
                        className={cn(
                          "mr-2 h-4 w-4",
                          selectedValue !== item.name ? "text-transparent" : "",
                        )}
                      />
                    </div>
                    <div className="grow">{item.name}</div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const ProcedureTypeFilter = ({
  selectedValue,
  onChange,
}: FilterProps<string>) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="outline" size="sm" className="h-10 border-dashed">
            <PlusCircleIcon className="mr-2 h-4 w-4" />
            <span>Procedimiento</span>

            {selectedValue && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />

                <Badge
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {ProcedureTypeMapper[selectedValue as ProcedureTypeEnum]}
                </Badge>
              </>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent className="p-0" side="bottom" align="start">
          <Command>
            <CommandInput placeholder="Filtrar tipo de documento" />
            <CommandList>
              <CommandEmpty>Sin resultados</CommandEmpty>

              {selectedValue && (
                <>
                  <CommandGroup>
                    <CommandItem
                      className="cursor-pointer"
                      onSelect={() => {
                        onChange(null);
                        setOpen(false);
                      }}
                    >
                      <XCircle className={cn("mr-2 h-4 w-4")} />
                      <span>Quitar filtro</span>
                    </CommandItem>
                  </CommandGroup>
                  <CommandSeparator />
                </>
              )}

              <CommandGroup>
                <CommandItem
                  className="flex cursor-pointer items-center"
                  key={ProcedureTypeEnum.AUTHORIZE}
                  value={ProcedureTypeEnum.AUTHORIZE}
                  onSelect={(value) => {
                    onChange(value);
                    setOpen(false);
                  }}
                >
                  <div>
                    <CheckIcon
                      className={cn(
                        "mr-2 h-4 w-4",
                        selectedValue !== ProcedureTypeEnum.AUTHORIZE
                          ? "text-transparent"
                          : "",
                      )}
                    />
                  </div>
                  <div className="grow">Autorización</div>
                </CommandItem>

                <CommandItem
                  className="flex cursor-pointer items-center"
                  key={ProcedureTypeEnum.CERTIFY}
                  value={ProcedureTypeEnum.CERTIFY}
                  onSelect={(value) => {
                    onChange(value);
                    setOpen(false);
                  }}
                >
                  <div>
                    <CheckIcon
                      className={cn(
                        "mr-2 h-4 w-4",
                        selectedValue !== "CERTIFY" ? "text-transparent" : "",
                      )}
                    />
                  </div>
                  <div className="grow">Certificación</div>
                </CommandItem>

                <CommandItem
                  className="flex cursor-pointer items-center"
                  key={ProcedureTypeEnum.SIMPLE}
                  value={ProcedureTypeEnum.SIMPLE}
                  onSelect={(value) => {
                    onChange(value);
                    setOpen(false);
                  }}
                >
                  <div>
                    <CheckIcon
                      className={cn(
                        "mr-2 h-4 w-4",
                        selectedValue !== "SIMPLE" ? "text-transparent" : "",
                      )}
                    />
                  </div>
                  <div className="grow">Firme electrónica simple</div>
                </CommandItem>

                <CommandItem
                  className="flex cursor-pointer items-center"
                  key={ProcedureTypeEnum.FEA}
                  value={ProcedureTypeEnum.FEA}
                  onSelect={(value) => {
                    onChange(value);
                    setOpen(false);
                  }}
                >
                  <div>
                    <CheckIcon
                      className={cn(
                        "mr-2 h-4 w-4",
                        selectedValue !== "FEA" ? "text-transparent" : "",
                      )}
                    />
                  </div>
                  <div className="grow">Firma electrónica avanzada</div>
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const DocumentAuthorFilter = ({
  selectedValue,
  onChange,
}: FilterProps<TLegalentityUserItem>) => {
  const [open, setOpen] = useState(false);

  const { legalEntity } = useAppStore();

  const query = useQuery({
    queryKey: ["listLegalEntitiyUsers", { legalEntityId: legalEntity?.id }],
    queryFn: () => listUsers({ legalEntityId: legalEntity?.id as string }),
    enabled: !!legalEntity,
  });

  const users = query.data ?? [];

  const selectedItem: TLegalentityUserItem | null =
    users.find((x) => `${x.firstName} ${x.lastName}` === selectedValue) ?? null;

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="outline" size="sm" className="h-10 border-dashed">
            <PlusCircleIcon className="mr-2 h-4 w-4" />
            <span>Autor</span>

            {selectedItem && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />

                <Badge
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {selectedItem.firstName} {selectedItem.lastName}
                </Badge>
              </>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent className="p-0" side="bottom" align="start">
          <Command>
            <CommandInput placeholder="Filtrar autor de documento" />
            <CommandList>
              <CommandEmpty>Sin resultados</CommandEmpty>

              {selectedItem && (
                <>
                  <CommandGroup>
                    <CommandItem
                      className="cursor-pointer"
                      onSelect={() => {
                        onChange(null);
                        setOpen(false);
                      }}
                    >
                      <XCircle className={cn("mr-2 h-4 w-4")} />
                      <span>Quitar filtro</span>
                    </CommandItem>
                  </CommandGroup>
                  <CommandSeparator />
                </>
              )}

              <CommandGroup>
                {users.map((item) => (
                  <CommandItem
                    className="flex cursor-pointer items-center"
                    key={item.documentNumber}
                    value={`${item.firstName} ${item.lastName}`}
                    onSelect={() => {
                      onChange(item);
                      setOpen(false);
                    }}
                  >
                    <div>
                      <CheckIcon
                        className={cn(
                          "mr-2 h-4 w-4",
                          selectedItem !== item ? "text-transparent" : "",
                        )}
                      />
                    </div>
                    <div className="grow">
                      {item.firstName} {item.lastName}
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const DocumentLabelsFilter = ({
  selectedValue,
  onChange,
}: FilterProps<string[]>) => {
  const { legalEntity } = useAppStore();
  const [open, setOpen] = useState(false);

  const query = useQuery({
    queryKey: ["listLegalEntitiyLabels", { legalEntityId: legalEntity?.id }],
    queryFn: () => listLabels({ legalEntityId: legalEntity?.id as string }),
    enabled: !!legalEntity,
  });

  const labels = query.data ?? [];
  const selectedLabels = (selectedValue as string[] | null) ?? [];

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="outline" size="sm" className="h-10 border-dashed">
            <PlusCircleIcon className="mr-2 h-4 w-4" />
            <span>Etiquetas</span>

            {selectedLabels.length > 0 && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />

                {selectedLabels.length >= 3 ? (
                  <Badge
                    variant="secondary"
                    className="mr-1 rounded-sm px-1 font-normal"
                  >
                    {selectedLabels.length} Opciones
                  </Badge>
                ) : (
                  selectedLabels.map((item) => (
                    <Badge
                      key={item}
                      variant="secondary"
                      className="mr-1 rounded-sm px-1 font-normal"
                    >
                      {item}
                    </Badge>
                  ))
                )}
              </>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent className="p-0" side="bottom" align="start">
          <Command>
            <CommandInput placeholder="Filtrar etiquetas" />
            <CommandList>
              <CommandEmpty>Sin resultados</CommandEmpty>

              {selectedValue && (
                <>
                  <CommandGroup>
                    <CommandItem
                      className="cursor-pointer"
                      onSelect={() => {
                        onChange(null);
                        setOpen(false);
                      }}
                    >
                      <XCircle className={cn("mr-2 h-4 w-4")} />
                      <span>Quitar filtro</span>
                    </CommandItem>
                  </CommandGroup>
                  <CommandSeparator />
                </>
              )}

              <CommandGroup>
                {labels.map((item) => (
                  <CommandItem
                    className="flex cursor-pointer items-center"
                    key={item}
                    value={item}
                    onSelect={(value) => {
                      if (selectedLabels?.includes(value)) {
                        const filteredLabels = selectedLabels.filter(
                          (x) => x !== value,
                        );
                        onChange(
                          filteredLabels.length === 0 ? null : filteredLabels,
                        );
                      } else {
                        onChange([...selectedLabels, value]);
                      }
                      setOpen(false);
                    }}
                  >
                    <div>
                      <CheckIcon
                        className={cn(
                          "mr-2 h-4 w-4",
                          !selectedLabels.includes(item)
                            ? "text-transparent"
                            : "",
                        )}
                      />
                    </div>
                    <div className="grow">{item}</div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export {
  DocumentAuthorFilter,
  DocumentLabelsFilter,
  DocumentStatusFilter,
  DocumentSubcategoryFilter,
  ProcedureTypeFilter,
};
