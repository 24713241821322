import { TDocumentItem } from "@/api/v2/documents";
import { createContext, useContext } from "react";

type CurrentDocumentState = {
  isLoading: boolean;
  currentDocument?: TDocumentItem;
  refetch: () => void;
};

const CurrentDocumentContext = createContext<CurrentDocumentState>({
  isLoading: false,
  refetch: () => {},
});

const useCurrentDocument = () => useContext(CurrentDocumentContext);

export { CurrentDocumentContext, useCurrentDocument };
