import { ReactNode } from "react";

const FieldLabel = ({ children }: { children: ReactNode }) => {
  return <div className="text-gray-500">{children}</div>;
};

const FieldValue = ({ children }: { children: ReactNode }) => {
  return <div>{children}</div>;
};

const Field = ({ children }: { children: ReactNode }) => {
  return <div>{children}</div>;
};

export { FieldLabel, FieldValue, Field };
