import {
  TDocumentItem,
  deleteAttachment,
  uploadAttachment,
} from "@/api/v2/documents";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Separator } from "@/components/ui/separator";
import { useCurrentDocument } from "@/context/current-document";
import { cn } from "@/lib/utils";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import {
  FileUpIcon,
  PlusIcon,
  Trash2Icon,
  UploadCloudIcon,
} from "lucide-react";
import { useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

const AttachmentsPicker = ({
  currentDocument,
  onUploadSuccess,
}: {
  currentDocument: TDocumentItem;
  onUploadSuccess: () => void;
}) => {
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [totalBytes, setTotalBytes] = useState(0);
  const [uploadedBytes, setUploadedBytes] = useState(0);

  const uploadProgress = useMemo(
    () => (totalBytes === 0 ? 0 : (uploadedBytes / totalBytes) * 100),
    [uploadedBytes, totalBytes],
  );

  const uploadMutation = useMutation({
    mutationKey: ["uploadAttachment", { code: currentDocument.code }],
    mutationFn: uploadAttachment,
  });

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    disabled: uploadMutation.isPending,
    onDrop: (files: File[]) => {
      setUploadedBytes(0);
      setTotalBytes(files.reduce((acc, x) => (acc += x.size), 0));

      Promise.allSettled(
        files.map((file) =>
          uploadMutation.mutateAsync({
            file,
            documentCode: currentDocument.code,
            onUploadProgress: (e) => setUploadedBytes((prev) => prev + e.bytes),
          }),
        ),
      ).then(() => onUploadSuccess());
    },
    onDropRejected: (fileRejections: FileRejection[]) => {
      let message = "";

      for (const err of fileRejections) {
        if (err.errors[0].message.startsWith("File is larger than")) {
          message =
            "El archivo es demasiado grande. Solo se admiten archivos de hasta 10MB";
          continue;
        } else {
          message = "El archivo no es un PDF";
          continue;
        }
      }

      setErrorMessage(message);
      setAlertOpen(true);
    },
    multiple: true,
    maxSize: 10 * 1024 * 1024,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  return (
    <>
      <input {...getInputProps()} type="file" className="hidden" />

      {currentDocument.supportingDocuments.length === 0 ? (
        <div
          className={cn(
            "cursor-pointer rounded-lg p-10 transition-all",
            "bg-zinc-100 hover:bg-zinc-200 dark:bg-zinc-800 hover:dark:bg-zinc-800/80",
          )}
          {...getRootProps()}
        >
          <div className="flex flex-col items-center justify-center gap-4">
            {uploadMutation.isPending ? (
              <>
                <FileUpIcon size={64} />

                <div className="w-[50%]">
                  <Progress
                    value={uploadProgress}
                    className={cn(
                      "animate-pulse bg-neutral-300 dark:bg-neutral-700",
                    )}
                  />
                </div>
              </>
            ) : (
              <>
                <UploadCloudIcon size={64} />

                <div className="text-center md:hidden">
                  Haz click para seleccionar los archivos (Solo archivos en
                  formato PDF)
                </div>

                <div className="hidden text-center md:block">
                  {isDragActive ? (
                    "Suelta aquí el archivo"
                  ) : (
                    <span>
                      Arrastra aquí los archivos o haz click para
                      seleccionarlos.
                      <br /> Solo archivos en formato PDF.
                      <br /> Tamaño máximo por archivo: 10MB
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <Button variant="outline" onClick={() => open()}>
            <div className="relative">
              {uploadMutation.isPending && (
                <div className="absolute left-0 top-0 mt-0.5 w-full">
                  <Progress
                    value={uploadProgress}
                    className={cn(
                      "rounded-full bg-neutral-300 dark:bg-neutral-700",
                    )}
                  />
                </div>
              )}

              <div className="flex items-center">
                <PlusIcon className="mr-2" size={18} />
                <span>Agregar más archivos</span>
              </div>
            </div>
          </Button>
        </div>
      )}

      <AlertDialog open={isAlertOpen} onOpenChange={setAlertOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Error de archivo</AlertDialogTitle>
            <AlertDialogDescription>{errorMessage}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cerrar</AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

const RemoveAttachmentModal = ({
  documentCode,
  attachmentCode,
  onRemove,
}: {
  documentCode: string;
  attachmentCode: string;
  onRemove: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const mutation = useMutation({
    mutationKey: ["removeAttachment", { documentCode, attachmentCode }],
    mutationFn: deleteAttachment,
    onSuccess: onRemove,
  });

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button size="icon" variant="destructive">
          <Trash2Icon size={16} />
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Eliminar firmante</AlertDialogTitle>

          <AlertDialogDescription>
            ¿Estás seguro de eliminar este anexo?
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel disabled={mutation.isPending}>
            Cancelar
          </AlertDialogCancel>

          <Button
            type="button"
            variant="destructive"
            disabled={mutation.isPending}
            onClick={() => mutation.mutate({ documentCode, attachmentCode })}
          >
            Confirmar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const AddAttachmentsPage = () => {
  const { currentDocument, refetch } = useCurrentDocument();
  const [parent] = useAutoAnimate();

  const attachments = currentDocument?.supportingDocuments ?? [];

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Anexos</CardTitle>
        </CardHeader>

        <Separator />

        <CardContent ref={parent} className="p-0">
          {attachments.map((item) => (
            <div
              key={item.code}
              className="flex items-center justify-between border-b px-3 py-2 last:rounded-b last:border-0 hover:bg-muted/40"
            >
              <div className="flex items-center justify-start truncate p-4">
                <FontAwesomeIcon
                  icon={faFilePdf}
                  size="lg"
                  className="text-primary-darker w-6 flex-none pr-4"
                />
                <div>{item.name}</div>
              </div>

              <div className="flex-none px-4">
                {currentDocument && (
                  <RemoveAttachmentModal
                    documentCode={currentDocument?.code}
                    attachmentCode={item.code}
                    onRemove={() => refetch()}
                  />
                )}
              </div>
            </div>
          ))}

          {currentDocument && (
            <div className="p-6">
              <AttachmentsPicker
                currentDocument={currentDocument}
                onUploadSuccess={() => refetch()}
              />
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export { AddAttachmentsPage };
