import { firmeClient } from "@/api/utils";
import { SigneeTypeRepresentation } from "@/enums/SigneeTypeRepresentation.enum.ts";
import { TPagedResponse, TPaginationFilters } from "@/types/pagination";
import axios, { AxiosHeaders, AxiosProgressEvent } from "axios";
import { z } from "zod";

const DocumentStatuses = [
  "PENDING",
  "UPLOADED",
  "DRAFT",
  "PENDING_SIGNATURE",
  "SIGNED",
  "PENDING_NOTARY_SIGNATURE",
  "NOTARIZED",
  "MISSING_ATTACHMENT",
  "REPAIR",
  "REJECTED",
  "CANCELLED",
  "COMPLETED",
  "REFUNDED",
] as const;

export enum ProcedureTypeEnum {
  AUTHORIZE = "AUTHORIZE",
  CERTIFY = "CERTIFY",
  PROTOCOLIZE = "PROTOCOLIZE",
  FEA = "FEA",
  SIMPLE = "SIMPLE",
}

export const ProcedureTypeMapper = {
  [ProcedureTypeEnum.SIMPLE]: "Firma Electrónica Simple",
  [ProcedureTypeEnum.FEA]: "Firma Electrónica Avanzada",
  [ProcedureTypeEnum.PROTOCOLIZE]: "Protocolización",
  [ProcedureTypeEnum.AUTHORIZE]: "Autorización",
  [ProcedureTypeEnum.CERTIFY]: "Certificación",
};

export const DocumentStatusEnum = z.enum(DocumentStatuses);
export type TDocumentStatus = z.infer<typeof DocumentStatusEnum>;

type ListDocumentsFilters = TPaginationFilters & {
  legalEntityId: string;
  transactionId?: string;
};

const SigneeItemRepresentation = z.object({
  type: z.nativeEnum(SigneeTypeRepresentation),
  documentNumber: z.string(),
  name: z.string(),
});

const DocumentSigneeItem = z.object({
  documentNumber: z.string(),
  country: z.string(),
  phoneNumber: z.string().nullish(),
  firstName: z.string(),
  lastName: z.string(),
  maternalLastName: z.string().nullish(),
  email: z.string(),
  hasIdentityCard: z.boolean(),
  municipality: z.string().nullish(),
  privateCode: z.string(),
  approvedUntil: z.string().nullable(),
  signedOn: z.string().nullish(),
  notarizedOn: z.string().nullish(),
  isVerified: z.boolean().nullish(),
  notary: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  coordinates: z
    .object({
      longitude: z.number().nullish(),
      latitude: z.number().nullish(),
    })
    .nullish(),
  coordinatesPdf: z
    .object({
      code: z.string(),
      page: z.number(),
      x: z.number(),
      y: z.number(),
    })
    .array()
    .default([]),
  inRepresentationOf: SigneeItemRepresentation.nullish(),
});

const SupportingDocumentSchema = z.object({
  name: z.string(),
  code: z.string(),
  description: z.string().nullish(),
  contentType: z.string(),
});

const DocumentItemSchema = z.object({
  id: z.string(),
  code: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  category: z.string().nullable(),
  subcategory: z.string().nullable(),
  status: DocumentStatusEnum,
  isProtocolized: z.boolean(),
  isArchived: z.boolean(),
  isNotarized: z.boolean(),
  signedOn: z.coerce.date().nullable(),
  notarizedOn: z.coerce.date().nullable(),
  transactionId: z.string(),
  procedureType: z.nativeEnum(ProcedureTypeEnum),
  user: z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
  }),
  legalEntity: z.object({
    id: z.string(),
    name: z.string(),
    documentNumber: z.string(),
  }),
  signees: z.array(DocumentSigneeItem),
  supportingDocuments: z.array(SupportingDocumentSchema),
  labels: z.array(z.string()),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

const DocumentsListItemSchema = z.object({
  id: z.string(),
  code: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  category: z.string(),
  subcategory: z.string(),
  status: DocumentStatusEnum,
  isProtocolized: z.boolean(),
  isArchived: z.boolean(),
  isNotarized: z.boolean(),
  signedOn: z.coerce.date().nullable(),
  notarizedOn: z.coerce.date().nullable(),
  transactionId: z.string(),
  procedureType: z.nativeEnum(ProcedureTypeEnum),
  user: z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
  }),
  legalEntity: z.object({
    id: z.string(),
    name: z.string(),
    documentNumber: z.string(),
  }),
  signeesCount: z.object({
    signed: z.number(),
    total: z.number(),
  }),
  labels: z.string().array(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

const DocumentLogItemSchema = z.object({
  id: z.string(),
  action: z.string(),
  description: z.string().nullable(),
  user: z
    .object({
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  createdAt: z.coerce.date(),
});

const UpdateDocumentSchema = z.object({
  code: z.string(),
  labels: z.string().array().optional(),
});

const CategoryItemSchema = z.object({
  name: z.string(),
  parent: z.string(),
  description: z.string().nullable(),
});

export type TDocumenSignee = z.infer<typeof DocumentSigneeItem>;
export type TSupportingDocumentItem = z.infer<typeof SupportingDocumentSchema>;
export type TDocumentItem = z.infer<typeof DocumentItemSchema>;
export type TListDocumentItem = z.infer<typeof DocumentsListItemSchema>;
export type TDocumentLogItem = z.infer<typeof DocumentLogItemSchema>;
export type TUpdateDocumentData = z.infer<typeof UpdateDocumentSchema>;
export type TCategoryItem = z.infer<typeof CategoryItemSchema>;

export type TCreateDocumentResponse = {
  id: string;
  code: string;
  transactionId: string;
  uploadUrl: string;
};

export type TCreateDocumentData = {
  transactionId: string;
  file: File;
};

export type TCreateSignatureData = {
  documentCode: string;
  privateCode: string;
  page: number;
  x: number;
  y: number;
};

export type TCreateSignatureResponse = {
  code: string;
  page: number;
  x: number;
  y: number;
  signee: {
    privateCode: string;
    firstName: string;
    lastName: string;
    maternalLastName: string;
  };
};

export type TUpdateSignatureData = {
  code: string;
} & Omit<TCreateSignatureData, "privateCode">;

export const listDocuments = async (filters: ListDocumentsFilters) => {
  return firmeClient
    .get<TPagedResponse<TListDocumentItem>>("/documents", {
      params: filters,
    })
    .then((res) => res.data);
};

export const getDocumentByCode = async (code: string) => {
  return firmeClient
    .get<TDocumentItem>(`/documents/${code}`)
    .then((res) => DocumentItemSchema.parse(res.data));
};

export const updateDocument = async ({
  code,
  ...data
}: TUpdateDocumentData) => {
  return firmeClient
    .put<TDocumentItem>(`/documents/${code}`, data)
    .then((res) => DocumentItemSchema.parse(res.data));
};

export const authorizeDocument = async ({ code }: TUpdateDocumentData) => {
  return firmeClient
    .post<TDocumentItem>(`/documents/${code}/authorize`)
    .then((res) => DocumentItemSchema.parse(res.data));
};

export const listDocumentLogs = async ({
  documentCode,
}: {
  documentCode: string;
}) => {
  return firmeClient
    .get<TPagedResponse<TDocumentLogItem>>(`/documents/${documentCode}/logs`)
    .then((res) => res.data);
};

export const createDocument = async (data: TCreateDocumentData) => {
  return firmeClient
    .post<TCreateDocumentResponse>("/documents", {
      transactionId: data.transactionId,
      name: data.file.name,
    })
    .then((res) => res.data);
};

export const notifyDocumentUpload = async (code: string) => {
  return firmeClient.put(`/documents/${code}/upload`).then((res) => res.data);
};

export const getDocumentFileURL = async ({
  code,
  fileKind,
}: {
  code: string;
  fileKind: string;
}) => {
  return firmeClient
    .get<{ download_url: string }>(`/documents/${code}/files/${fileKind}`)
    .then((res) => res.data);
};

export const createSignature = async ({
  documentCode,
  ...data
}: TCreateSignatureData) => {
  return firmeClient
    .post<TCreateSignatureResponse>(
      `/documents/${documentCode}/signatures`,
      data,
    )
    .then((res) => res.data);
};

export const updateSignature = async ({
  code,
  documentCode,
  ...data
}: TUpdateSignatureData) => {
  return firmeClient
    .put<TCreateSignatureResponse>(
      `/documents/${documentCode}/signatures/${code}`,
      data,
    )
    .then((res) => res.data);
};

export const deleteSignature = async ({
  documentCode,
  code,
}: {
  documentCode: string;
  code: string;
}) => {
  return firmeClient
    .delete(`/documents/${documentCode}/signatures/${code}`)
    .then((res) => res.data);
};

export const uploadAttachment = async ({
  documentCode,
  file,
  onUploadProgress,
}: {
  documentCode: string;
  file: File;
  onUploadProgress?: (e: AxiosProgressEvent) => void;
}) => {
  return firmeClient
    .post<{
      uploadUrl: string;
    }>(`/documents/${documentCode}/attachments`, {
      name: file.name,
      contentType: file.type,
    })
    .then(async (res) => {
      const headers = new AxiosHeaders();
      headers.set("Content-Type", file.type);

      await axios.put(res.data.uploadUrl, file, {
        headers,
        onUploadProgress: onUploadProgress,
      });

      return firmeClient.post<{
        code: string;
        name: string;
        contentType: string;
        description: string | null;
      }>(`/documents/${documentCode}/attachments/upload`, {
        name: file.name,
        contentType: file.type,
      });
    });
};

export const confirmAttachmentUpload = async ({
  documentCode,
  file,
}: {
  documentCode: string;
  file: File;
  onUploadProgress?: (e: AxiosProgressEvent) => void;
}) => {
  return firmeClient.post<{
    code: string;
    name: string;
    contentType: string;
    description: string | null;
  }>(`/documents/${documentCode}/attachments/upload`, {
    name: file.name,
    contentType: file.type,
  });
};

export const deleteAttachment = async ({
  documentCode,
  attachmentCode,
}: {
  documentCode: string;
  attachmentCode: string;
}) => {
  return firmeClient
    .delete(`/documents/${documentCode}/attachments/${attachmentCode}`)
    .then((res) => res.data);
};

export const listCategories = async () => {
  return firmeClient
    .get<TCategoryItem[]>("/categories")
    .then((res) => res.data);
};

export const deleteDocument = async ({ code }: { code: string }) => {
  return firmeClient.delete(`/documents/${code}`).then((res) => res.data);
};

export const downloadDocument = async ({
  code,
  fileKind,
  filename,
}: {
  code: string;
  fileKind: string;
  filename: string;
}) => {
  return getDocumentFileURL({ code, fileKind }).then(async (data) => {
    return axios
      .get(data.download_url, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        return new File([res.data], filename, {
          type: "application/pdf",
        });
      });
  });
};
