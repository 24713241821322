import { z } from "zod";
import { firmeClient } from "@/api/utils";
import { ProcedureTypeEnum } from "./documents";

const PaymentStatusMap = {
  PENDING: "PENDING",
  PAID: "PAID",
  REJECTED: "REJECTED",
  IN_REVIEW: "IN_REVIEW",
  UNINVOICED: "UNINVOICED",
  INVOICED: "INVOICED",
} as const;

export const PaymentMethodMap = {
  CREDITS: "CREDITS",
  MERCADOPAGO: "MERCADOPAGO",
} as const;

const PaymentStatusSchema = z.nativeEnum(PaymentStatusMap);
const PaymentMethodSchema = z.nativeEnum(PaymentMethodMap);

type TCreateTransactionData = {
  legalEntityId: string;
};

type TUpdateTransactionData = {
  transactionId: string;
  notaryId?: string;
};

const TransactionItemSchema = z.object({
  id: z.string(),
  documentsCodes: z.string().array(),
  status: z.string(),
  legalEntityDocumentNumber: z.string(),
  paymentStatus: PaymentStatusSchema,
  mercadoPagoId: z.string().optional(),
  mercadoPagoUrl: z.string().optional(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  notary: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
});

const TransactionTotalSchema = z.object({
  total: z.number(),
  items: z
    .object({
      name: z.string(),
      quantity: z.number(),
      price: z.number(),
      procedureType: z.nativeEnum(ProcedureTypeEnum),
    })
    .array(),
});

const TransactionCompleteResponseSchema = z.object({
  paymentUrl: z.string().nullable(),
});

export type TTransactionTotal = z.infer<typeof TransactionTotalSchema>;
export type TTransactionItem = z.infer<typeof TransactionItemSchema>;
export type TPaymentMethod = z.infer<typeof PaymentMethodSchema>;
export type TTransactionCompleteResponse = z.infer<
  typeof TransactionCompleteResponseSchema
>;

export type TCompleteTransactionData = {
  transactionId: string;
  paymentMethod: TPaymentMethod;
};

export const createTransaction = async (data: TCreateTransactionData) => {
  return firmeClient
    .post<{
      id: string;
    }>("/transactions", data)
    .then((res) => res.data);
};

export const getTransactionById = async (transactionId: string) => {
  return firmeClient
    .get(`/transactions/${transactionId}`)
    .then((res) => TransactionItemSchema.parse(res.data));
};

export const updateTransaction = async ({
  transactionId,
  ...data
}: TUpdateTransactionData) => {
  return firmeClient
    .put<{
      id: string;
    }>(`/transactions/${transactionId}`, data)
    .then((res) => res.data);
};

export const getTransactionTotal = async (transactionId: string) => {
  return firmeClient
    .get(`/transactions/${transactionId}/total`)
    .then((res) => TransactionTotalSchema.parse(res.data));
};

export const completeTransaction = async ({
  transactionId,
  paymentMethod,
}: TCompleteTransactionData) => {
  return firmeClient
    .post(`/transactions/${transactionId}/complete`, {
      paymentMethod,
    })
    .then((res) => TransactionCompleteResponseSchema.parse(res.data));
};
