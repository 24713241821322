import { format, parseISO } from "date-fns";
import { fromZonedTime } from "date-fns-tz";

function formatDate(value: string | Date, fmt?: string) {
  if (value instanceof Date) {
    return format(fromZonedTime(value, "UTC"), fmt ?? "dd/MM/yyyy");
  } else {
    return format(fromZonedTime(parseISO(value), "UTC"), fmt ?? "dd/MM/yyyy");
  }
}

export { formatDate };
