import { ProcedureTypeMapper, getDocumentByCode } from "@/api/v2/documents";
import { DocumentPreview } from "@/components/Documents/DocumentPreview";
import { DocumentCreateSteps } from "@/components/Transactions/TransactionCreateSteps";
import { TransactionHeaderPortal } from "@/components/Transactions/TransactionHeaderPortal";
import { Button } from "@/components/ui/button";
import { Field, FieldLabel, FieldValue } from "@/components/ui/fields";
import { CurrentDocumentContext } from "@/context/current-document";
import { formatDate } from "@/helpers/formatDate";
import {
  useCurrentStep,
  useTransactionNextStep,
} from "@/hooks/transactions-hooks";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQuery } from "@tanstack/react-query";
import { Outlet, useParams } from "react-router-dom";

const TransactionCreateLayout = () => {
  const currentStep = useCurrentStep();
  const [parent] = useAutoAnimate();

  const { documentCode } = useParams<{
    transactionId: string;
    documentCode?: string;
  }>();

  const query = useQuery({
    queryKey: ["getDocumentByCode", { code: documentCode }],
    queryFn: () => getDocumentByCode(documentCode as string),
    enabled: !!documentCode,
  });

  const currentDocument = query.data;
  const { nextStepHandler } = useTransactionNextStep(currentDocument);
  const showStepIndicator = currentStep !== "files";
  const showDocumentPreview = currentStep !== "new";

  return (
    <>
      <CurrentDocumentContext.Provider
        value={{
          currentDocument,
          isLoading: query.isStale && query.isLoading,
          refetch: query.refetch,
        }}
      >
        <TransactionHeaderPortal>
          {showStepIndicator && (
            <div className="w-full border-b">
              <div className="container grid grid-cols-12 gap-6 py-6">
                <div className="col-span-12">
                  <DocumentCreateSteps currentDocument={currentDocument} />
                </div>
              </div>
            </div>
          )}

          {currentDocument && showDocumentPreview && (
            <div className="w-full border-b bg-muted/50 dark:bg-background">
              <div className="container grid grid-cols-12 gap-6 py-6">
                <div className="col-span-2">
                  <DocumentPreview
                    documentCode={currentDocument.code}
                    height={16}
                  />
                </div>

                <div className="col-span-10 grid grid-cols-3 gap-4">
                  <div className="space-y-4">
                    <Field>
                      <FieldLabel>Nombre</FieldLabel>
                      <FieldValue>{currentDocument.name}</FieldValue>
                    </Field>

                    <Field>
                      <FieldLabel>Descripción</FieldLabel>
                      <FieldValue>
                        {currentDocument.description ?? "Sin descripción"}
                      </FieldValue>
                    </Field>

                    <Field>
                      <FieldLabel>Categoría</FieldLabel>
                      <FieldValue>{currentDocument.subcategory}</FieldValue>
                    </Field>
                  </div>

                  <div className="space-y-4">
                    <Field>
                      <FieldLabel>Código</FieldLabel>
                      <FieldValue>
                        <code>
                          {currentDocument.code
                            .split("-")
                            .pop()
                            ?.slice(0, 5)
                            .toUpperCase()}
                        </code>
                      </FieldValue>
                    </Field>

                    <Field>
                      <FieldLabel>Procedimiento</FieldLabel>
                      <FieldValue>
                        {ProcedureTypeMapper[currentDocument.procedureType] ??
                          "No indicado"}
                      </FieldValue>
                    </Field>

                    <Field>
                      <FieldLabel>Fecha de creación</FieldLabel>
                      <FieldValue>
                        {currentDocument?.createdAt
                          ? formatDate(
                              currentDocument?.createdAt,
                              "dd/MM/yyyy HH:mm:ss",
                            )
                          : "-"}
                      </FieldValue>
                    </Field>
                  </div>

                  {currentStep !== "summary" && currentStep !== "payment" && (
                    <div className="flex flex-col justify-end gap-4">
                      <Button className="w-full" onClick={nextStepHandler}>
                        Continuar
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </TransactionHeaderPortal>

        <div className="flex flex-col gap-4" ref={parent}>
          <Outlet />
        </div>
      </CurrentDocumentContext.Provider>
    </>
  );
};

export { TransactionCreateLayout };
