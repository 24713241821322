import { firmeClient } from "@/api/utils";
import { ProcedureTypeEnum } from "./documents";

export type TNotaryItem = {
  id: string;
  name: string;
  procedures: ProcedureTypeEnum[];
};

export const getNotaries = async () => {
  return firmeClient.get<TNotaryItem[]>("/notaries").then((res) => res.data);
};
