import { TDocumenSignee } from "@/api/v2/documents";
import { updateSignee } from "@/api/v2/signees";
import { Button } from "@/components/ui/button";
import { SigneeTypeRepresentation } from "@/enums/SigneeTypeRepresentation.enum";
import { validateRut } from "@/helpers/validateRut";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Loader2, PencilIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import { RUTInput } from "../RUTInput";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { Separator } from "../ui/separator";
import { AlertError } from "../ui/alert-error";

const documentNumberSchema = z
  .string()
  .max(13, {
    message: "RUT ingresado no es válido",
  })
  .regex(/^[0-9]{1,3}\.[0-9]{3}\.[0-9]{3}-[K0-9]{1}$/, {
    message: "RUT ingresado no es válido",
  })
  .refine((value) => validateRut(value), {
    message: "Dígito verificador no es válido",
  });

const formSchema = z.object({
  documentNumber: z.string().min(1, {
    message: "RUN es requerido",
  }),
  firstName: z.string().min(1, {
    message: "Nombre es requerido",
  }),
  lastName: z.string().min(1, {
    message: "Apellido paterno es requerido",
  }),
  maternalLastName: z
    .string()
    .min(1, {
      message: "Apellido materno es requerido",
    })
    .nullish(),
  email: z.string().email("Correo electrónico ingresado no es válido"),

  inRepresentationOf: z
    .object({
      name: z.string().nullable(),
      documentNumber: documentNumberSchema,
    })
    .nullish(),
});

type TSigneeUpdateForm = z.infer<typeof formSchema>;

type TEditSigneeModalProps = {
  documentCode: string;
  signee: TDocumenSignee;
};

export const EditSigneeModal = ({
  documentCode,
  signee,
}: TEditSigneeModalProps) => {
  const [isOpen, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const form = useForm<TSigneeUpdateForm>({
    resolver: zodResolver(formSchema),
    defaultValues: signee,
  });

  const mutation = useMutation({
    mutationKey: ["updateSignee"],
    mutationFn: updateSignee,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getDocumentByCode", { code: documentCode }],
      });
      toast.info("Firmante actualizado correctamente");
      setOpen(false);
    },
    onError: () => toast.error("Hubo un error al actualizar el firmante"),
  });

  if (signee.signedOn) {
    return null;
  }

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="icon">
          <PencilIcon size={16} />
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editar firmante</DialogTitle>
          <DialogDescription>
            {signee.firstName} {signee.lastName}
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            className="flex flex-col gap-4"
            onSubmit={form.handleSubmit((data) =>
              mutation.mutate({
                documentCode,
                privateCode: signee.privateCode,
                ...data,
              }),
            )}
          >
            <FormField
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nombres</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      disabled={mutation.isPending}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Apellido Paterno</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      disabled={mutation.isPending}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="maternalLastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Apellido Materno</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      disabled={mutation.isPending}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="documentNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>RUT</FormLabel>
                  <FormControl>
                    <RUTInput
                      fullSize
                      rut={field.value}
                      setRut={field.onChange}
                      {...field}
                      disabled={mutation.isPending}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      disabled={mutation.isPending}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {signee.inRepresentationOf?.documentNumber && (
              <>
                <Separator />

                <FormField
                  name="inRepresentationOf.name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {signee.inRepresentationOf?.type ===
                        SigneeTypeRepresentation.Natural
                          ? "Nombre de la persona a representar"
                          : "Nombre de la empresa a representar"}
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          {...field}
                          disabled={mutation.isPending}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="inRepresentationOf.documentNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {signee.inRepresentationOf?.type ===
                        SigneeTypeRepresentation.Natural
                          ? "RUT de la persona a representar"
                          : "RUT de la empresa a representar"}
                      </FormLabel>
                      <FormControl>
                        <RUTInput
                          fullSize
                          rut={field.value}
                          setRut={field.onChange}
                          {...field}
                          disabled={mutation.isPending}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}

            {mutation.isError && <AlertError error={mutation.error} />}

            <DialogFooter>
              <div className="mt-4 flex w-full items-center justify-between">
                <Button
                  variant="outline"
                  type="button"
                  disabled={mutation.isPending}
                  onClick={() => {
                    form.reset();
                    setOpen(false);
                  }}
                >
                  Cancelar
                </Button>

                <Button
                  type="submit"
                  className={
                    mutation.isPending ? "cursor-not-allowed opacity-50" : ""
                  }
                >
                  {mutation.isPending ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      <span>Guardando...</span>
                    </>
                  ) : (
                    <span>Guardar</span>
                  )}
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
