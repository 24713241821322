import * as Sentry from "@sentry/react";

import { Navigate, createBrowserRouter } from "react-router-dom";

import { ErrorPage } from "./pages/ErrorPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { RootLayout } from "./layouts/RootLayout";
import { AppLayout } from "./layouts/AppLayout";
import { LegalEntityLayout } from "./layouts/LegalEntityLayout";

import { LoginPage } from "./pages/LoginPage";
import { DocumentsPage } from "./pages/Documents/DocumentsPage";
import { TransactionFilesPage } from "./pages/Transactions/TransactionFilesPage";
import { AddSigneesPage } from "./pages/Transactions/AddSigneesPage";
import { TransactionNotaryPage } from "./pages/Transactions/TransactionNotaryPage";
import { TransactionCreateLayout } from "./layouts/TransactionCreateLayout";
import { RequiresAuth } from "./components/requires-auth";
import { ErrorMessage } from "./components/error-message";
import { AddAttachmentsPage } from "./pages/Transactions/AddAttachmentsPage";
import { TransactionSummaryPage } from "@/pages/Transactions/TransactionSummaryPage";
import { AddSignaturesPage } from "@/pages/Documents/AddSignaturesPage";
import { TransactionDocumentPage } from "./pages/Transactions/TransactionDocumentPage";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <RequiresAuth />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                index: true,
                errorElement: <ErrorMessage />,
                element: <DocumentsPage />,
              },
              {
                path: "/transactions/:transactionId",
                element: <TransactionCreateLayout />,
                children: [
                  {
                    path: "files",
                    errorElement: <ErrorMessage />,
                    element: <TransactionFilesPage />,
                  },
                  {
                    path: "documents/:documentCode/new",
                    errorElement: <ErrorMessage />,
                    element: <TransactionDocumentPage />,
                  },
                  {
                    path: "documents/:documentCode/signees",
                    errorElement: <ErrorMessage />,
                    element: <AddSigneesPage />,
                  },
                  {
                    path: "documents/:documentCode/signatures",
                    errorElement: <ErrorMessage />,
                    element: <AddSignaturesPage />,
                  },
                  {
                    path: "documents/:documentCode/attachments",
                    errorElement: <ErrorMessage />,
                    element: <AddAttachmentsPage />,
                  },
                  {
                    path: "documents/:documentCode/notary",
                    errorElement: <ErrorMessage />,
                    element: <TransactionNotaryPage />,
                  },
                  {
                    path: "documents/:documentCode/summary",
                    errorElement: <ErrorMessage />,
                    element: <TransactionSummaryPage />,
                  },
                  {
                    path: "documents/:documentCode/summary/payment",
                    errorElement: <ErrorMessage />,
                    element: <TransactionSummaryPage />,
                  },
                ],
              },
              {
                path: "/documents",
                children: [
                  {
                    index: true,
                    element: <Navigate to="/" />,
                  },
                  {
                    path: ":code",
                    errorElement: <ErrorMessage />,
                    async lazy() {
                      const { DocumentDetailsPage } = await import(
                        "@/pages/Documents/DocumentsDetailPage"
                      );
                      return { Component: DocumentDetailsPage };
                    },
                  },
                  /* {
                    path: ":documentCode/edit",
                    errorElement: <ErrorMessage />,
                    async lazy() {
                      const { DocumentModificationPage } = await import(
                        "@/pages/Documents/ModifyDocument/DocumentModificationPage"
                      );
                      return { Component: DocumentModificationPage };
                    },
                  }, */
                ],
              },
              {
                path: "/legal-entities",
                children: [
                  {
                    index: true,
                    errorElement: <ErrorMessage />,
                    async lazy() {
                      const { LegalEntitiesPage } = await import(
                        "@/pages/LegalEntities/LegalEntitiesPage"
                      );
                      return { Component: LegalEntitiesPage };
                    },
                  },
                  {
                    path: "new",
                    errorElement: <ErrorMessage />,
                    async lazy() {
                      const { EnrollLegalEntityPage } = await import(
                        "@/pages/LegalEntities/EnrollLegalEntityPage"
                      );
                      return { Component: EnrollLegalEntityPage };
                    },
                  },
                  {
                    path: ":legalEntityId",
                    element: <LegalEntityLayout />,
                    errorElement: <ErrorMessage />,
                    children: [
                      {
                        index: true,
                        errorElement: <ErrorMessage />,
                        async lazy() {
                          const { LegalEntityGeneralPage } = await import(
                            "@/pages/LegalEntities/LegalEntityGeneralPage"
                          );
                          return { Component: LegalEntityGeneralPage };
                        },
                      },
                      {
                        path: "users",
                        errorElement: <ErrorMessage />,
                        async lazy() {
                          const { LegalEntityUsersPage } = await import(
                            "@/pages/LegalEntities/LegalEntityUsersPage"
                          );
                          return { Component: LegalEntityUsersPage };
                        },
                      },
                      {
                        path: "representatives",
                        errorElement: <ErrorMessage />,
                        async lazy() {
                          const { LegalEntityRepresentativesPage } =
                            await import(
                              "@/pages/LegalEntities/LegalEntityRepresentativesPage"
                            );
                          return { Component: LegalEntityRepresentativesPage };
                        },
                      },
                      {
                        path: "credits",
                        errorElement: <ErrorMessage />,
                        async lazy() {
                          const { LegalEntityCreditsPage } = await import(
                            "@/pages/LegalEntities/LegalEntityCreditsPage"
                          );
                          return { Component: LegalEntityCreditsPage };
                        },
                      },
                      {
                        path: "labels",
                        errorElement: <ErrorMessage />,
                        async lazy() {
                          const { LegalEntityLabelsPage } = await import(
                            "@/pages/LegalEntities/LegalEntityLabelsPage"
                          );
                          return { Component: LegalEntityLabelsPage };
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "/profile",
                errorElement: <ErrorMessage />,
                async lazy() {
                  const { ProfilePage } = await import(
                    "@/pages/Profile/ProfilePage"
                  );
                  return { Component: ProfilePage };
                },
              },
            ],
          },
        ],
      },
      {
        path: "/login",
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <LoginPage />,
          },
        ],
      },
      {
        path: "not-found",
        children: [
          {
            index: true,
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

export default router;
