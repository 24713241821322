import {
  ProcedureTypeEnum,
  TDocumentItem,
  updateDocument,
} from "@/api/v2/documents";
import { TProductItem, listProducts } from "@/api/v2/products";
import { CategoriesSelector } from "@/components/Transactions/CategoriesSelector";
import { cn, formatCurrency } from "@/lib/utils";
import { useAppStore } from "@/stores/AppStore";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ArrowRightIcon, Loader2Icon } from "lucide-react";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { Separator } from "../ui/separator";
import { Textarea } from "../ui/textarea";
import { AlertError } from "../ui/alert-error";

const formSchema = z.object({
  name: z
    .string({
      required_error: "Nombre de documento es requerido",
    })
    .min(1, "Nombre es requerido"),
  description: z.string().nullable(),
  category: z.string(),
  subcategory: z
    .string({ required_error: "Categoría es requerida" })
    .min(1, "Categiría es requerida"),
  procedureType: z.nativeEnum(ProcedureTypeEnum),
  isProtocolized: z.boolean(),
});

type TDocumentForm = z.infer<typeof formSchema>;

const ProcedureItem = ({
  product,
  disabled,
}: {
  product?: TProductItem;
  disabled?: boolean;
}) => {
  const { legalEntity } = useAppStore();

  if (!product) return;

  return (
    <Label
      htmlFor={product.procedureType}
      className={cn(
        "flex flex-col",
        "cursor-pointer rounded-md border p-6",
        "hover:bg-accent",
        disabled && "opacity-60",
      )}
    >
      <div className="flex flex-row gap-3">
        <RadioGroupItem
          id={product.procedureType}
          value={product.procedureType}
          disabled={disabled}
        />
        <div className="pt-0.5 font-bold">
          <div>{product.name}</div>
          <div className="flex gap-3">
            {disabled ? (
              <Badge className="mt-2">Próximamente</Badge>
            ) : (
              legalEntity?.personType === "NATURAL" &&
              product && (
                <Badge className="mt-2">{formatCurrency(product.price)}</Badge>
              )
            )}
          </div>
        </div>
      </div>

      <FormDescription className="pl-7 pt-2">
        {product.description}
      </FormDescription>
    </Label>
  );
};

const DocumentForm = ({
  currentDocument,
  onSave,
}: {
  currentDocument: TDocumentItem;
  onSave: (data: TDocumentItem) => void;
}) => {
  const { legalEntity } = useAppStore();
  const documentName = currentDocument.name.split(".")[0];

  const productsQuery = useQuery({
    queryKey: ["listProducts"],
    queryFn: listProducts,
  });

  const mutation = useMutation({
    mutationKey: ["updateDocument", { code: currentDocument.code }],
    mutationFn: updateDocument,
  });

  const form = useForm<TDocumentForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: documentName,
      description: currentDocument.description ?? "",
      category: currentDocument.category ?? undefined,
      subcategory: currentDocument.subcategory ?? undefined,
      procedureType: currentDocument.procedureType
        ? (currentDocument.procedureType as ProcedureTypeEnum)
        : ProcedureTypeEnum.FEA,
      isProtocolized: currentDocument.isProtocolized ?? false,
    },
    disabled: mutation.isPending,
  });

  const watchProcedureType = form.watch("procedureType");

  const canProtocolize =
    watchProcedureType === ProcedureTypeEnum.AUTHORIZE ||
    watchProcedureType === ProcedureTypeEnum.CERTIFY;

  const productsByProcedure = useMemo(() => {
    const group: Map<ProcedureTypeEnum, TProductItem> = new Map();
    for (const item of productsQuery.data ?? []) {
      group.set(item.procedureType, item);
    }
    return group;
  }, [productsQuery.data]);

  const protocolizeProduct = useMemo(
    () => productsByProcedure.get(ProcedureTypeEnum.PROTOCOLIZE),
    [productsByProcedure],
  );

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit((data) => {
          mutation.mutate(
            {
              code: currentDocument.code,
              ...data,
            },
            {
              onSuccess: (data) => onSave(data),
            },
          );
        })}
      >
        <FormField
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Nombre del documento</FormLabel>
              <FormControl>
                <Input type="text" autoFocus {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center gap-2">
                Descripción
                <FormDescription>(Opcional)</FormDescription>
              </FormLabel>

              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="subcategory"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Categoría</FormLabel>
              <FormControl>
                <CategoriesSelector
                  defaultValue={{
                    subcategory: field.value,
                    category: field.value,
                  }}
                  onChange={(selection) => {
                    if (selection) {
                      field.onChange(selection.subcategory);
                      form.setValue("category", selection.category);
                    }
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="procedureType"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Tipo de procedimiento</FormLabel>

              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <div className="flex flex-col gap-4">
                    <ProcedureItem
                      product={productsByProcedure.get(
                        ProcedureTypeEnum.SIMPLE,
                      )}
                      disabled
                    />
                    <ProcedureItem
                      product={productsByProcedure.get(ProcedureTypeEnum.FEA)}
                    />
                    <ProcedureItem
                      product={productsByProcedure.get(
                        ProcedureTypeEnum.CERTIFY,
                      )}
                    />
                    <ProcedureItem
                      product={productsByProcedure.get(
                        ProcedureTypeEnum.AUTHORIZE,
                      )}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {canProtocolize && (
          <>
            <Separator />

            <FormField
              name="isProtocolized"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <label
                      htmlFor="isProtocolized"
                      className={cn(
                        "flex flex-col",
                        "cursor-pointer rounded-md border p-6",
                        "hover:bg-accent",
                      )}
                    >
                      <div className="flex flex-row items-center gap-3">
                        <Checkbox
                          id="isProtocolized"
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                        <div className="text-sm font-bold">
                          <div>¿Protocolizar documento?</div>
                          {legalEntity?.personType === "NATURAL" &&
                            protocolizeProduct && (
                              <Badge className="mt-2">
                                {formatCurrency(protocolizeProduct.price)}
                              </Badge>
                            )}
                        </div>
                      </div>

                      <FormDescription className="pl-7 pt-2">
                        {
                          productsByProcedure.get(ProcedureTypeEnum.PROTOCOLIZE)
                            ?.description
                        }
                      </FormDescription>
                    </label>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}

        <div className="flex justify-end gap-4 pt-2">
          <Button type="submit" variant="default" disabled={mutation.isPending}>
            {mutation.isPending ? (
              <>
                <Loader2Icon className="mr-2 animate-spin" />
                <span>Cargando...</span>
              </>
            ) : (
              <>
                <span>Continuar</span>
                <ArrowRightIcon className="ml-2" />
              </>
            )}
          </Button>
        </div>

        {mutation.isError && <AlertError error={mutation.error} />}
      </form>
    </Form>
  );
};

export { DocumentForm };
