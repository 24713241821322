import { getQuota } from "@/api/v2/legal-entities";
import darkLogo from "@/assets/images/firme-dark.png";
import whiteLogo from "@/assets/images/firme-white.png";
import { useTheme } from "@/components/theme-provider";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { useAppStore } from "@/stores/AppStore";
import { useTransactionStore } from "@/stores/TransactionStore";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  faBars,
  faBuilding,
  faCartPlus,
  faChevronDown,
  faCoins,
  faIdBadge,
  faMoon,
  faPlus,
  faSignOut,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopoverClose } from "@radix-ui/react-popover";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Separator } from "../ui/separator";
import { Skeleton } from "../ui/skeleton";
import { Table, TableBody, TableCell, TableRow } from "../ui/table";
import { LegalEntitySelector } from "./LegalEntitySelector";

const ThemePicker = () => {
  const { theme, setTheme } = useTheme();

  return (
    <Button
      variant="outline"
      onClick={() => setTheme(theme === "light" ? "dark" : "light")}
    >
      {theme === "light" ? (
        <FontAwesomeIcon icon={faMoon} className="w-4" />
      ) : (
        <FontAwesomeIcon icon={faSun} className="w-4" />
      )}
    </Button>
  );
};

const UserMenu = ({ signOut }: { signOut: () => void }) => {
  const { user, legalEntity } = useAppStore();

  const legalEntityName = useMemo(
    () =>
      legalEntity?.documentNumber !== user?.documentNumber
        ? ` - ${legalEntity?.name}`
        : " - Personal",
    [user, legalEntity],
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        disabled={!user && !legalEntity}
        className="hidden md:block"
        asChild
      >
        {!user && !legalEntity ? (
          <Skeleton className="h-10 w-40" />
        ) : (
          <Button variant="outline">
            <span>
              {user?.firstName} {user?.lastName} {legalEntityName}
            </span>
            <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
          </Button>
        )}
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="min-w-[250px]">
        <DropdownMenuLabel>
          <span>{user?.email}</span>
        </DropdownMenuLabel>

        <DropdownMenuSeparator />
        <DropdownMenuItem asChild className="cursor-pointer">
          <Link to="/profile" className="block">
            <FontAwesomeIcon icon={faIdBadge} className="mr-2" />
            <span>Mi perfil</span>
          </Link>
        </DropdownMenuItem>

        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <FontAwesomeIcon icon={faBuilding} className="mr-2" />
              <span>Cambiar de empresa</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuSubContent className="p-0">
              <LegalEntitySelector />
            </DropdownMenuSubContent>
          </DropdownMenuSub>

          <DropdownMenuItem asChild className="cursor-pointer">
            <Link to="/legal-entities" className="block">
              <FontAwesomeIcon icon={faBuilding} className="mr-2" />
              <span>Mis empresas</span>
            </Link>
          </DropdownMenuItem>

          <DropdownMenuItem asChild className="cursor-pointer">
            <Link to="/legal-entities/new" className="block">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              <span>Inscribir empresa</span>
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>

        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          <DropdownMenuItem asChild>
            <button
              type="button"
              className="w-full cursor-pointer"
              onClick={() => {
                useAppStore.getState().clear();
                useTransactionStore.getState().reset();
                signOut();
              }}
            >
              <FontAwesomeIcon icon={faSignOut} className="mr-2" />
              <span>Cerrar sesión</span>
            </button>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const MagicLinkButton = () => {
  return null;
  // const { mutate: getSrdMagicLink, isLoading: isGettingSrdMagicLink } =
  //   useMutation({
  //     mutationFn: async () => {
  //       const { token } = await getSession();

  //       const { data } = await apiClient.post(
  //         "/srd/cognito",
  //         {},
  //         {
  //           headers: {
  //             Authorization: token,
  //           },
  //         },
  //       );

  //       return data as string;
  //     },
  //     onSuccess: (magicLinkUrl) => {
  //       window.open(magicLinkUrl, "_blank");
  //     },
  //   });

  // return (
  //   <>
  //     {isGettingSrdMagicLink ? (
  //       <div className="flex select-none flex-row items-center justify-center gap-2">
  //         <Loader />
  //         <p>Obteniendo enlace...</p>
  //       </div>
  //     ) : (
  //       <div
  //         onClick={() => getSrdMagicLink()}
  //         className="flex cursor-pointer flex-row items-center justify-center gap-2 hover:underline"
  //       >
  //         <FontAwesomeIcon icon={faLink} />
  //         <p>Mis invitaciones a firmar</p>
  //       </div>
  //     )}
  //   </>
  // );
};

const DocumentsQuota = () => {
  const { legalEntity } = useAppStore();

  const query = useQuery({
    queryKey: ["getQuota", { legalEntityId: legalEntity?.id }],
    queryFn: () => getQuota({ legalEntityId: legalEntity?.id as string }),
    enabled: !!legalEntity,
  });

  const certificationCredits = query.data?.certified ?? 0;
  const authorizationCredits = query.data?.authorized ?? 0;
  const protocolizationCredits = query.data?.protocolized ?? 0;
  const feaCredits = query.data?.fea ?? 0;
  const simpleCredits = query.data?.simple ?? 0;

  const totalCredits =
    certificationCredits +
    authorizationCredits +
    protocolizationCredits +
    simpleCredits +
    feaCredits;

  return (
    <Popover>
      <PopoverTrigger
        className={cn(
          buttonVariants({ variant: "outline" }),
          "hidden md:block",
          query.isLoading && "p-0",
        )}
      >
        {query.isLoading ? (
          <Skeleton className="h-10 w-16" />
        ) : (
          <>
            <FontAwesomeIcon icon={faCoins} className="mr-2" />
            <strong>{totalCredits}</strong>
          </>
        )}
      </PopoverTrigger>

      <PopoverContent className="p-0" side="bottom" align="end">
        <div className="p-3">
          Créditos disponibles para <br /> <strong>{legalEntity?.name}</strong>
        </div>

        <Separator />

        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Certificaciones</TableCell>
              <TableCell align="right">
                <strong className="text-primary">{certificationCredits}</strong>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Autorizaciones</TableCell>
              <TableCell align="right">
                <strong className="text-primary">{authorizationCredits}</strong>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Protocolizaciones</TableCell>
              <TableCell align="right">
                <strong className="text-primary">
                  {protocolizationCredits}
                </strong>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Firme Electrónica Simple</TableCell>
              <TableCell align="right">
                <strong className="text-primary">{simpleCredits}</strong>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Firme Electrónica Avanzada</TableCell>
              <TableCell align="right">
                <strong className="text-primary">{feaCredits}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Separator />

        <div className="p-3">
          <PopoverClose
            className={cn(buttonVariants({ variant: "default" }), "w-full")}
            asChild
          >
            <Link to={`/legal-entities/${legalEntity?.id}/credits`}>
              <FontAwesomeIcon icon={faCartPlus} className="mr-2" />
              <span>Comprar más créditos</span>
            </Link>
          </PopoverClose>
        </div>
      </PopoverContent>
    </Popover>
  );
};

const Header = () => {
  const { theme } = useTheme();
  const { authStatus, signOut } = useAuthenticator();
  const { openDrawer } = useAppStore();

  return (
    <header className="w-full border-b py-4 md:relative">
      {authStatus === "authenticated" && (
        <div className="absolute top-3 md:hidden">
          <button className="p-5" type="button" onClick={openDrawer}>
            <FontAwesomeIcon icon={faBars} size="xl" />
          </button>
        </div>
      )}

      <div className="container flex flex-row items-center justify-between">
        <div
          className={cn(
            "mr-2 flex flex-row items-center justify-start",
            "ml-10 md:ml-0",
          )}
        >
          <Link to={"/"}>
            <img
              src={theme === "dark" ? whiteLogo : darkLogo}
              alt="Firme"
              width="180px"
            />
          </Link>

          {authStatus === "authenticated" && (
            <div className="ml-4 hidden md:block">
              <MagicLinkButton />
            </div>
          )}
        </div>

        <div className="flex items-center gap-3">
          {authStatus === "authenticated" && (
            <>
              <DocumentsQuota />
              <UserMenu signOut={signOut} />
            </>
          )}
          <ThemePicker />
        </div>
      </div>
    </header>
  );
};

export { Header };
