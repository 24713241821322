import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

const TransactionHeaderPortal = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const ref = useRef<HTMLElement>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector("#subheader-portal") as HTMLElement;
    setMounted(true);
  }, []);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export { TransactionHeaderPortal };
