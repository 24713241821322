import { z } from "zod";
import { firmeClient } from "../utils";

const currentUserSchema = z.object({
  id: z.string(),
  subId: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  documentNumber: z.string(),
});

export type TCurrentUser = z.infer<typeof currentUserSchema>;

export const getCurrentUser = async () => {
  return firmeClient
    .get("/users/me")
    .then((res) => currentUserSchema.parse(res.data));
};
