import { TDocumenSignee, TDocumentItem } from "@/api/v2/documents";
import { removeSignee } from "@/api/v2/signees";
import { EditSigneeModal } from "@/components/Modals/EditSigneeModal";
import { SigneeForm } from "@/components/Transactions/SigneeForm";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { AlertError } from "@/components/ui/alert-error";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { useCurrentDocument } from "@/context/current-document";
import { formatearRut } from "@/helpers/rut.helpers";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Trash2Icon } from "lucide-react";
import { useState } from "react";

const RemoveSigneeModal = ({
  documentCode,
  signee,
  onRemove,
}: {
  documentCode: string;
  signee: TDocumenSignee;
  onRemove: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const mutation = useMutation({
    mutationKey: ["removeSignee", { documentCode }],
    mutationFn: removeSignee,
    onSuccess: onRemove,
  });

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button size="icon" variant="destructive">
          <Trash2Icon size={16} />
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Eliminar firmante</AlertDialogTitle>

          <AlertDialogDescription>
            ¿Estás seguro de eliminar el firmante{" "}
            <strong>
              {signee.firstName} {signee.lastName} {signee.maternalLastName}
            </strong>
            ?
          </AlertDialogDescription>
        </AlertDialogHeader>

        {mutation.isError && <AlertError error={mutation.error} />}

        <AlertDialogFooter>
          <AlertDialogCancel disabled={mutation.isPending}>
            Cancelar
          </AlertDialogCancel>

          <Button
            type="button"
            variant="destructive"
            disabled={mutation.isPending}
            onClick={() =>
              mutation.mutate({ documentCode, privateCode: signee.privateCode })
            }
          >
            Confirmar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const SigneesList = ({
  currentDocument,
  isLoading,
}: {
  currentDocument?: TDocumentItem;
  isLoading: boolean;
}) => {
  const queryClient = useQueryClient();
  const [parent] = useAutoAnimate();

  const signees = currentDocument?.signees ?? [];

  return (
    <Table>
      <TableBody ref={parent}>
        {!isLoading && signees.length === 0 && (
          <TableRow>
            <TableCell colSpan={4} align="center" className="py-6">
              Agrega al menos un firmante para continuar con el proceso
            </TableCell>
          </TableRow>
        )}

        {isLoading &&
          [1].map((id) => (
            <TableRow key={id}>
              <TableCell className="w-64">
                <Skeleton className="h-6 w-full" />
              </TableCell>

              <TableCell>
                <Skeleton className="h-6 w-full" />
              </TableCell>

              <TableCell>
                <Skeleton className="h-6 w-full" />
              </TableCell>

              <TableCell>
                <Skeleton className="h-6 w-full" />
              </TableCell>
            </TableRow>
          ))}

        {signees.map((item) => (
          <TableRow key={item.privateCode}>
            <TableCell className="pl-6">
              <div>
                {item.firstName} {item.lastName} {item.maternalLastName}
              </div>

              {item.inRepresentationOf && (
                <div className="text-accent-foreground/50">
                  <span>En representación de </span>
                  <strong>
                    {item.inRepresentationOf.name} (
                    {formatearRut(item.inRepresentationOf.documentNumber)})
                  </strong>
                </div>
              )}
            </TableCell>

            <TableCell>{formatearRut(item.documentNumber)}</TableCell>

            <TableCell>{item.email}</TableCell>

            <TableCell className="space-x-4 pr-6" align="right">
              {/* <Button size="icon" variant="secondary"> */}
              {/*   <PencilIcon size={16} /> */}
              {/* </Button> */}

              {currentDocument && (
                <EditSigneeModal
                  signee={item}
                  documentCode={currentDocument.code}
                />
              )}

              {currentDocument && (
                <RemoveSigneeModal
                  signee={item}
                  documentCode={currentDocument.code}
                  onRemove={() =>
                    queryClient.refetchQueries({
                      queryKey: [
                        "getDocumentByCode",
                        { code: currentDocument.code },
                      ],
                    })
                  }
                />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const AddSigneesPage = () => {
  const { currentDocument, isLoading, refetch } = useCurrentDocument();

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Firmantes</CardTitle>
        </CardHeader>

        <Separator />

        <CardContent className="p-0">
          <SigneesList
            currentDocument={currentDocument}
            isLoading={isLoading}
          />
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Agregar Firmante</CardTitle>
        </CardHeader>

        <CardContent>
          <SigneeForm
            currentDocument={currentDocument}
            onSave={() => refetch()}
          />
        </CardContent>
      </Card>
    </>
  );
};

export { AddSigneesPage };
