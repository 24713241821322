import { LoginPage } from "@/pages/LoginPage";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Outlet } from "react-router-dom";

const RequiresAuth = () => {
  const { route } = useAuthenticator((context) => [context.route]);

  if (route === "authenticated") {
    return <Outlet />;
  }

  return <LoginPage />;
};

export { RequiresAuth };
