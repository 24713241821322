import { ProcedureTypeEnum, TDocumentItem } from "@/api/v2/documents";
import { TTransactionItem } from "@/api/v2/transactions";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useCurrentStep = () => {
  const location = useLocation();
  return useMemo(() => {
    const path = location.pathname.split("/");
    return path[path.length - 1];
  }, [location.pathname]);
};

const useTransactionNextStep = (currentDocument?: TDocumentItem) => {
  const navigate = useNavigate();
  const currentStep = useCurrentStep();

  const stepsMap = useMemo(() => {
    if (
      currentDocument?.procedureType === ProcedureTypeEnum.FEA ||
      currentDocument?.procedureType === ProcedureTypeEnum.SIMPLE
    ) {
      return {
        signees: "attachments",
        attachments: "summary",
        signatures: null,
        notary: null,
      } as Record<string, string | null>;
    } else if (currentDocument?.procedureType == ProcedureTypeEnum.AUTHORIZE) {
      return {
        signees: "attachments",
        signatures: null,
        attachments: "notary",
        notary: "summary",
      } as Record<string, string | null>;
    }

    return {
      signees: "signatures",
      signatures: "attachments",
      attachments: "notary",
      notary: "summary",
    } as Record<string, string | null>;
  }, [currentDocument?.procedureType]);

  const nextStepHandler = useCallback(() => {
    const nextStep = stepsMap[currentStep];
    const to = `/transactions/${currentDocument?.transactionId}/documents/${currentDocument?.code}/${nextStep}`;
    navigate(to);
  }, [currentDocument, currentStep, stepsMap, navigate]);

  return { nextStepHandler };
};

const usePaymentStatus = (transaction?: TTransactionItem) => {
  return useMemo(
    () => ({
      status: transaction?.paymentStatus,
      isPending: transaction?.paymentStatus === "PENDING",
      isPaid:
        transaction?.paymentStatus === "PAID" ||
        transaction?.paymentStatus === "UNINVOICED",
      isInReview: transaction?.paymentStatus === "IN_REVIEW",
    }),
    [transaction?.paymentStatus],
  );
};

export { useTransactionNextStep, useCurrentStep, usePaymentStatus };
