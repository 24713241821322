import { ReactNode } from "react";
import { v4 as uuid } from "uuid";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TCurrentUser } from "@/api/v2/users";
import { TLegalEntityItem } from "@/api/v2/legal-entities";

export interface INotification {
  id: string;
  message: ReactNode;
  type: "SUCCESS" | "INFO" | "DANGER" | "WARN";
}

type AppState = {
  user?: TCurrentUser;
  legalEntity?: TLegalEntityItem;
  isDrawerOpen: boolean;
  notifications: INotification[];

  setUser: (user: TCurrentUser) => void;
  setLegalEntity: (legalEntity: TLegalEntityItem) => void;
  closeDrawer: () => void;
  openDrawer: () => void;
  notify: (type: INotification["type"], message: ReactNode) => void;
  removeNotification: (id: string) => void;

  clear: () => void;
};

const useAppStore = create(
  persist<AppState>(
    (set) => ({
      user: undefined,
      legalEntity: undefined,
      isDrawerOpen: false,
      notifications: [],

      setUser: (user) =>
        set(() => ({
          user,
        })),

      setLegalEntity: (legalEntity) =>
        set(() => ({
          legalEntity,
        })),

      openDrawer: () =>
        set((state) => ({
          ...state,
          isDrawerOpen: true,
        })),

      closeDrawer: () =>
        set((state) => ({
          ...state,
          isDrawerOpen: false,
        })),

      notify: (type, message) =>
        set((state) => ({
          notifications: [
            { id: uuid(), type, message },
            ...state.notifications,
          ],
        })),

      removeNotification: (id) =>
        set((state) => ({
          notifications: state.notifications.filter((x) => x.id !== id),
        })),

      clear: () => set(() => ({ user: undefined, legalEntity: undefined })),
    }),
    {
      name: "app-store",
      version: 6,
    },
  ),
);

export { useAppStore };
