import { AlertOctagon } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./ui/button";

const ErrorMessage = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-6 rounded-md bg-muted/80 p-10">
      <h1 className="text-2xl">Ha ocurrido un error inesperado</h1>

      <AlertOctagon height="100px" width="100%" />

      <div>
        <Button onClick={() => window.location.reload()}>
          <FontAwesomeIcon icon={faRefresh} className="mr-3" />
          <span>Recargar</span>
        </Button>
      </div>
    </div>
  );
};

export { ErrorMessage };
