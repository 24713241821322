import { listLegalEntities } from "@/api/v2/legal-entities";
import { getCurrentUser } from "@/api/v2/users";
import { Header } from "@/components/Header/Header";
import { Sidebar } from "@/components/Sidebar";
import { useAppStore } from "@/stores/AppStore";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const useFetchCurrentUser = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) => {
  const { setUser, setLegalEntity, legalEntity } = useAppStore();

  const currentUserQuery = useQuery({
    queryKey: ["getCurrentUser"],
    queryFn: getCurrentUser,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated,
  });

  const legalEntitiesQuery = useQuery({
    queryKey: ["getLegalEntities"],
    queryFn: listLegalEntities,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated,
  });

  useEffect(() => {
    if (currentUserQuery.data) {
      setUser(currentUserQuery.data);
    }
  }, [currentUserQuery.data, setUser]);

  useEffect(() => {
    if (legalEntitiesQuery.data) {
      if (!legalEntity && legalEntitiesQuery.data.items.length > 0) {
        setLegalEntity(legalEntitiesQuery.data.items[0]);
      }
    }
  }, [legalEntitiesQuery.data, legalEntity, setLegalEntity]);
};

const RootLayout = () => {
  const { authStatus } = useAuthenticator((context) => [context.user]);
  useFetchCurrentUser({ isAuthenticated: authStatus === "authenticated" });

  return (
    <div className="flex h-screen flex-col items-stretch">
      <Header />
      {authStatus === "authenticated" && <Sidebar />}
      <Outlet />
      <footer className="pointer-events-none py-8"></footer>
    </div>
  );
};

export { RootLayout };
