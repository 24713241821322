import { cn } from "@/lib/utils";
import { Outlet, useNavigation } from "react-router-dom";

const AppLayout = () => {
  const { state } = useNavigation();

  return (
    <>
      <section id="subheader-portal"></section>

      <main
        className={cn(
          "container grow py-4",
          state === "loading" && "animate-pulse opacity-40",
        )}
      >
        <Outlet />
      </main>
    </>
  );
};

export { AppLayout };
