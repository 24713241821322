import { z } from "zod";
import { firmeClient } from "../utils";
import { ProcedureTypeEnum } from "./documents";

const ProductItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  price: z.number(),
  quantity: z.number(),
  procedureType: z.nativeEnum(ProcedureTypeEnum),
});

export type TProductItem = z.infer<typeof ProductItemSchema>;

export const listProducts = async () => {
  return firmeClient
    .get("/products")
    .then((res) => ProductItemSchema.array().parse(res.data));
};
