import { ApiError, parseError } from "@/api/utils";
import { Alert } from "./alert";
import { AlertCircleIcon } from "lucide-react";

const AlertError = ({ error }: { error: ApiError }) => {
  return (
    <Alert variant="destructive">
      <div className="flex gap-3">
        <AlertCircleIcon />
        {parseError(error)}
      </div>
    </Alert>
  );
};

export { AlertError };
