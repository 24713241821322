import { cn } from "@/lib/utils";
import React, { ElementRef, useEffect, useMemo, useRef, useState } from "react";
import { Document as PDFDocument, Page, pdfjs } from "react-pdf";
import { Virtuoso } from "react-virtuoso";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

const PDFViewer = ({
  fileUrl,
  className,
  onLoadError,
}: {
  fileUrl: string;
  className?: string;
  onLoadError?: () => void;
}) => {
  const file = useMemo(() => ({ url: fileUrl }), [fileUrl]);
  const [numPages, setNumPages] = useState(0);
  const [pageSize, setPageSize] = useState({ width: 0, height: 0 });

  const wrapperRef = useRef<ElementRef<"div">>(null);

  const [pageRefs, setPageRefs] = useState<
    Map<number, React.RefObject<HTMLDivElement>>
  >(new Map());

  const handleResize = () => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();

      setPageSize({
        width: rect.width,
        height: rect.height,
      });
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      ref={wrapperRef}
      className={cn(className ? className : "h-[90%] w-full text-center")}
    >
      <PDFDocument
        file={file}
        className="rounded-lg bg-gray-100 p-2"
        onLoadSuccess={({ numPages }) => {
          const refs: Map<number, React.RefObject<HTMLDivElement>> = new Map();
          for (let i = 1; i <= numPages; i++) {
            refs.set(i, React.createRef<HTMLDivElement>());
          }
          setPageRefs(refs);
          setNumPages(numPages);
        }}
        loading={() => (
          <div
            className="flex w-full items-center justify-center"
            style={{
              height: pageSize.height,
            }}
          >
            Cargando documento...
          </div>
        )}
        onLoadError={onLoadError}
      >
        <Virtuoso
          className="scrollbar-thumb-rounded-lg scrollbar-thin scrollbar-track-transparent scrollbar-thumb-secondary-400 h-full w-full overflow-auto"
          totalCount={numPages}
          increaseViewportBy={{
            top: 200,
            bottom: 200,
          }}
          style={{
            height: pageSize.height,
          }}
          itemContent={(index: number) => (
            <Page
              inputRef={pageRefs.get(index + 1)}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              className="bg-secondary-300 mx-auto inline-block border text-center drop-shadow-sm"
              loading={() => (
                <div
                  className="bg-transparent"
                  style={{
                    width: pageSize.width - 100,
                    height: pageSize.height,
                  }}
                >
                  Cargando...
                </div>
              )}
            />
          )}
        />
      </PDFDocument>
    </div>
  );
};

export { PDFViewer };
