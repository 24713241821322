import { parseError } from "@/api/utils";
import { ProcedureTypeEnum, TDocumentItem } from "@/api/v2/documents";
import {
  PaymentMethodMap,
  TPaymentMethod,
  TTransactionItem,
  TTransactionTotal,
  completeTransaction,
  getTransactionById,
  getTransactionTotal,
} from "@/api/v2/transactions";
import { Alert } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@/components/ui/table";
import { useCurrentDocument } from "@/context/current-document";
import { cn, creditsQuantityRender, formatCurrency } from "@/lib/utils";
import { useAppStore } from "@/stores/AppStore";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  AlertCircleIcon,
  CheckIcon,
  FilesIcon,
  Loader2Icon,
  StampIcon,
  UsersIcon,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type ProductItemProps = {
  title: string;
  description: string;
  price: string;
  url: string;
};

const ProductItem = ({ title, price }: ProductItemProps) => {
  return (
    <Card className="border-primary">
      <CardHeader className="pb-2 text-center">
        {/* <Badge className="mb-3 w-max self-center uppercase">Most popular</Badge> */}
        <CardTitle className="!mb-7">{title}</CardTitle>
        <span className="text-5xl font-bold">{price}</span>
      </CardHeader>

      <CardDescription className="mx-auto w-11/12 text-center">
        All the basics for starting a new business
      </CardDescription>

      <CardContent>
        <ul className="mt-7 space-y-2.5 text-sm">
          <li className="flex space-x-2">
            <CheckIcon className="mt-0.5 h-4 w-4 flex-shrink-0" />
            <span className="text-muted-foreground">2 user</span>
          </li>
          <li className="flex space-x-2">
            <CheckIcon className="mt-0.5 h-4 w-4 flex-shrink-0" />
            <span className="text-muted-foreground">Plan features</span>
          </li>
          <li className="flex space-x-2">
            <CheckIcon className="mt-0.5 h-4 w-4 flex-shrink-0" />
            <span className="text-muted-foreground">Product support</span>
          </li>
        </ul>
      </CardContent>

      <CardFooter>
        <Button className="w-full">Sign up</Button>
      </CardFooter>
    </Card>
  );
};

const ProductsModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/");
  const isPayment = path[path.length - 1] === "payment";

  const [open, setOpen] = useState(isPayment);

  useEffect(() => {
    if (!open && isPayment) {
      setOpen(true);
    }
  }, [open, isPayment]);

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          setOpen(false);
          navigate(path.slice(0, path.length - 1).join("/"));
        }
      }}
    >
      <DialogTrigger asChild></DialogTrigger>

      {/* <DialogContent className="flex h-[90%] min-w-[95%] flex-col lg:min-w-[1000px]"> */}
      <DialogContent
        className="flex min-w-[95%] flex-col lg:min-w-[1000px]"
        overlayClassName="bg-slate-50 dark:bg-gray-900"
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>Comprar Créditos</DialogTitle>
          <DialogDescription>
            Lorem ipsum dolor sit amet, officia excepteur ex fugiat
          </DialogDescription>
        </DialogHeader>

        <div className="grid grid-cols-3 items-center gap-4">
          <ProductItem
            title="Créditos para este documento"
            description="Lorem ipsum dolor sit amet, qui minim labore adipisicing minim sint cillum sint consectetur cupidatat."
            price="$90.000"
            url="https://mpago.la/1DyJCjJ"
          />

          <ProductItem
            title="5 Certificaciones Notariales"
            description="Notariza hasta 5 documentos con certificación."
            price="$90.000"
            url="https://mpago.la/1DyJCjJ"
          />

          <ProductItem
            title="5 Certificaciones Notariales"
            description="Notariza hasta 5 documentos con certificación."
            price="$90.000"
            url="https://mpago.la/1DyJCjJ"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const TransactionPayment = ({
  transaction,
  currentDocument,
  legalEntityId,
  transactionTotal,
}: {
  transaction: TTransactionItem;
  currentDocument: TDocumentItem;
  transactionTotal: TTransactionTotal;
  legalEntityId: string;
}) => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const queryClient = useQueryClient();
  const [paymentMethod, setPaymentMethod] = useState<TPaymentMethod>();

  const completeMutation = useMutation({
    mutationKey: [
      "completeTransaction",
      { transactionId: currentDocument.transactionId },
    ],
    mutationFn: completeTransaction,
    onSuccess: (data) => {
      if (data.paymentUrl) {
        setIsRedirecting(true);
        window.location.href = data.paymentUrl;
      } else {
        queryClient.refetchQueries({
          queryKey: ["getTransaction", { id: currentDocument.transactionId }],
        });

        queryClient.refetchQueries({
          queryKey: ["getQuota", { legalEntityId }],
        });
      }
    },
  });

  if (transaction.paymentStatus === "PAID") {
    return <Alert variant="primary">Documento pagado exitosamente</Alert>;
  }

  return (
    <>
      <RadioGroup
        className="space-y-3"
        onValueChange={(value) => setPaymentMethod(value as TPaymentMethod)}
      >
        <Label
          htmlFor="credits"
          className={cn(
            "flex ",
            "cursor-pointer rounded-md border p-6",
            "hover:bg-gray-200 hover:dark:bg-gray-900",
            paymentMethod === "CREDITS" && "bg-gray-200 dark:bg-gray-900",
          )}
        >
          <div className="flex flex-row gap-3">
            <RadioGroupItem id="credits" value={PaymentMethodMap.CREDITS} />

            <div className="pt-0.5 font-bold">
              <div>Pagar con mis créditos</div>
            </div>
          </div>
        </Label>

        <Label
          htmlFor="mercadopago"
          className={cn(
            "flex flex-col",
            "cursor-pointer rounded-md border p-6",
            "hover:bg-gray-200 hover:dark:bg-gray-900",
            paymentMethod === "MERCADOPAGO" && "bg-gray-200 dark:bg-gray-900",
          )}
        >
          <div className="flex flex-row gap-3">
            <RadioGroupItem
              id="mercadopago"
              value={PaymentMethodMap.MERCADOPAGO}
            />

            <div className="flex w-full items-center justify-between pt-0.5 font-bold">
              <div>Pagar con Mercado Pago</div>
              <div>{formatCurrency(transactionTotal.total ?? 0)}</div>
            </div>
          </div>
        </Label>
      </RadioGroup>

      <Button
        size="lg"
        className="w-full"
        disabled={!paymentMethod || completeMutation.isPending || isRedirecting}
        onClick={() => {
          completeMutation.reset();

          paymentMethod &&
            completeMutation.mutate({
              transactionId: currentDocument.transactionId,
              paymentMethod,
            });
        }}
      >
        {!paymentMethod ? (
          <span>Selecciona una forma de pago</span>
        ) : completeMutation.isPending || isRedirecting ? (
          <>
            <Loader2Icon className="mr-2 animate-spin" size={18} />
            <span>Finalizando transacción...</span>
          </>
        ) : paymentMethod === "MERCADOPAGO" ? (
          <span>Ir a pagar y enviar solicitudes de firma</span>
        ) : (
          <span>Finalizar y enviar solicitudes de firma</span>
        )}
      </Button>

      {completeMutation.isError && !!completeMutation.error && (
        <Alert variant="destructive">
          <div className="flex gap-3">
            <AlertCircleIcon />
            {parseError(
              completeMutation.error as Error | AxiosError<{ detail: string }>,
            )}
          </div>
        </Alert>
      )}
    </>
  );
};

const ProductsSummary = ({
  currentDocument,
  transaction,
  isLoading,
}: {
  currentDocument?: TDocumentItem;
  transaction?: TTransactionItem;
  isLoading: boolean;
}) => {
  const { legalEntity } = useAppStore();
  const [parent] = useAutoAnimate();

  const totalQuery = useQuery<
    TTransactionTotal,
    Error | AxiosError<{ detail: string }>
  >({
    queryKey: [
      "getTransactionTotal",
      { transactionId: currentDocument?.transactionId },
    ],
    queryFn: () =>
      getTransactionTotal(currentDocument?.transactionId as string),
    retry: false,
    enabled: !!currentDocument?.transactionId,
  });

  const transactionTotal = totalQuery.data;
  const items = transactionTotal?.items ?? [];

  return (
    <div className="space-y-6" ref={parent}>
      <Card>
        <CardHeader>
          <CardTitle>Resumen de transacción</CardTitle>

          <CardDescription>
            Este es el detalle de los créditos que serán consumidos en esta
            transacción.
          </CardDescription>
        </CardHeader>

        <Separator />

        {totalQuery.isError && (
          <div className="p-6">
            <Alert variant="destructive">{parseError(totalQuery.error)}</Alert>
          </div>
        )}

        <Table className="overflow-hidden">
          {(isLoading || totalQuery.isLoading) && (
            <TableBody>
              {Array.from({ length: 2 }).map((_, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <Skeleton className="h-5 w-48" />
                  </TableCell>

                  <TableCell align="right">
                    <Skeleton className="h-5 w-32" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}

          {items.length > 0 && (
            <>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.procedureType}>
                    <TableCell className="px-6">{item.name}</TableCell>
                    <TableCell className="px-6 text-right">
                      {legalEntity?.personType === "NATURAL"
                        ? formatCurrency(item.price)
                        : creditsQuantityRender(item.quantity)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              {legalEntity?.personType === "NATURAL" && (
                <TableFooter className="bg-transparent">
                  <TableRow>
                    <TableCell className="px-6">
                      <strong>Total</strong>
                    </TableCell>
                    <TableCell className="px-6 text-right">
                      <strong>
                        {formatCurrency(transactionTotal?.total ?? 0)}
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
      </Card>

      {currentDocument && transaction && transactionTotal && legalEntity && (
        <TransactionPayment
          currentDocument={currentDocument}
          transactionTotal={transactionTotal}
          legalEntityId={legalEntity.id}
          transaction={transaction}
        />
      )}
    </div>
  );
};

const TransactionSummaryPage = () => {
  const { currentDocument } = useCurrentDocument();

  const query = useQuery({
    queryKey: ["getTransaction", { id: currentDocument?.transactionId }],
    queryFn: () => getTransactionById(currentDocument?.transactionId as string),
    enabled: !!currentDocument,
  });

  const currentTransaction = query.data;
  const currentNotary = currentTransaction?.notary;

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="space-y-6">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-3">
              <UsersIcon size={16} />
              <span>Firmantes</span>
            </CardTitle>
          </CardHeader>

          <Separator className="mb-6" />

          <CardContent>
            {currentDocument?.signees.length === 0 && <div>Sin Firmantes</div>}

            <div className="space-y-4">
              {currentDocument?.signees.map((item) => (
                <div key={item.privateCode}>
                  {item.firstName} {item.lastName} {item.maternalLastName} (
                  {item.email})
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-3">
              <FilesIcon size={16} />
              <span>Anexos</span>
            </CardTitle>
          </CardHeader>

          <Separator className="mb-6" />

          <CardContent>
            {currentDocument?.supportingDocuments.length === 0 && (
              <div>Sin Anexos</div>
            )}

            {currentDocument?.supportingDocuments.map((item) => (
              <div key={item.code}>
                <div>{item.name}</div>
              </div>
            ))}
          </CardContent>
        </Card>

        {(currentDocument?.procedureType == ProcedureTypeEnum.CERTIFY ||
          currentDocument?.procedureType == ProcedureTypeEnum.AUTHORIZE) && (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-3">
                <StampIcon size={16} />
                <span>Notario</span>
              </CardTitle>
            </CardHeader>

            <Separator className="mb-6" />

            <CardContent>{currentNotary?.name ?? "Sin Notario"}</CardContent>
          </Card>
        )}
      </div>

      <ProductsSummary
        currentDocument={currentDocument}
        transaction={currentTransaction}
        isLoading={query.isLoading}
      />

      <ProductsModal />
    </div>
  );
};

export { TransactionSummaryPage };
