const validateRut = (rut: string) => {
  rut = rut.replace(/[.-]/g, "");
  if (!/^\d{1,8}[0-9Kk]$/.test(rut)) {
    return false;
  }
  const rutDigits = rut.slice(0, -1);
  const rutDv = rut.slice(-1).toUpperCase();

  let sum = 0;
  let multiplier = 2;

  for (let i = rutDigits.length - 1; i >= 0; i--) {
    sum += parseInt(rutDigits.charAt(i)) * multiplier;
    multiplier = (multiplier % 7) + 1;
    if (multiplier === 1) {
      multiplier = 2;
    }
  }

  const expectedDv = 11 - (sum % 11);
  const calculatedDv =
    expectedDv === 11 ? "0" : expectedDv === 10 ? "K" : expectedDv.toString();

  return rutDv === calculatedDv;
};

export { validateRut };
