import Axios, { AxiosError } from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { z } from "zod";

export type ApiError = Error | AxiosError;

const StandardErrorSchema = z.object({
  type: z.literal("standard").default("standard"),
  detail: z.string(),
});

const ValidationErrorSchema = z.object({
  type: z.literal("validation").default("validation"),
  detail: z
    .object({
      type: z.string(),
      loc: z.string().array(),
      msg: z.string().transform((x) => x.replace("Value error, ", "")),
      input: z.string(),
    })
    .array(),
});

const ErrorDetailsSchema = z.union([
  StandardErrorSchema,
  ValidationErrorSchema,
]);

const FIRME_API_URL = import.meta.env.VITE_FIRME_API_URL;

export const getSession = async () => {
  const session = await fetchAuthSession();
  const token = session.tokens?.idToken?.toString();
  return { token, sub: session.userSub };
};

export const firmeClient = Axios.create({
  baseURL: FIRME_API_URL,
});

firmeClient.interceptors.request.use(
  async (config) => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.toString();
      config.headers.setAuthorization(`Bearer ${token}`);
    } catch (e) {
      console.error(e);
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export const parseError = (err: ApiError) => {
  if (axios.isAxiosError(err)) {
    const error = ErrorDetailsSchema.parse(err.response?.data);

    if (error.type === "standard") {
      return error.detail;
    }

    if (error.type === "validation") {
      return error.detail.map((x) => x.msg).join(", ");
    }
  }

  if (err instanceof Error && "issues" in err) {
    console.error(err);
    return "Error de validación";
  }

  return err.message;
};
